/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */
export const messages = {
  'locales.en-GB': 'English',
  'locales.de-DE': 'Deutsch',
  'locales.fr-FR': 'Français',
  'stores.uk': 'UK (GBP)',
  'stores.eu': 'EU (EUR)',
  'stores.us': 'US (USD)',
  'countries.A1': 'Proxy anonyme',
  'countries.A2': 'Fournisseur de satellite',
  'countries.AD': 'Andorre',
  'countries.AE': 'Émirats arabes unis',
  'countries.AF': 'Afghanistan',
  'countries.AG': 'Antigua-et-Barbuda',
  'countries.AI': 'Anguilla',
  'countries.AL': 'Albanie',
  'countries.AM': 'Arménie',
  'countries.AN': 'Antilles néerlandaises',
  'countries.AO': 'Angola',
  'countries.AP': 'Région Asie/Pacifique',
  'countries.AQ': 'Antarctique',
  'countries.AR': 'Argentine',
  'countries.AS': 'Samoa américaines',
  'countries.AT': 'Autriche',
  'countries.AU': 'Australie',
  'countries.AW': 'Aruba',
  'countries.AX': 'Îles d’Åland',
  'countries.AZ': 'Azerbaïdjan',
  'countries.BA': 'Bosnie-Herzégovine',
  'countries.BB': 'Barbade',
  'countries.BD': 'Bangladesh',
  'countries.BE': 'Belgique',
  'countries.BF': 'Burkina Faso',
  'countries.BG': 'Bulgarie',
  'countries.BH': 'Bahreïn',
  'countries.BI': 'Burundi',
  'countries.BJ': 'Bénin',
  'countries.BM': 'Bermudes',
  'countries.BN': 'Brunéi Darussalam',
  'countries.BO': 'Bolivie',
  'countries.BR': 'Brésil',
  'countries.BS': 'Bahamas',
  'countries.BT': 'Bhoutan',
  'countries.BV': 'Île Bouvet',
  'countries.BW': 'Botswana',
  'countries.BY': 'Biélorussie',
  'countries.BZ': 'Belize',
  'countries.CA': 'Canada',
  'countries.CC': 'Îles Cocos (Keeling)',
  'countries.CD': 'Congo',
  'countries.CF': 'République centrafricaine',
  'countries.CG': 'Congo',
  'countries.CH': 'Suisse',
  'countries.CI': "Côte d'Ivoire",
  'countries.CK': 'Îles Cook',
  'countries.CL': 'Chili',
  'countries.CM': 'Cameroun',
  'countries.CN': 'Chine',
  'countries.CO': 'Colombie',
  'countries.CR': 'Costa Rica',
  'countries.CU': 'Cuba',
  'countries.CV': 'Cap-Vert',
  'countries.CX': 'Île Christmas',
  'countries.CY': 'Chypre',
  'countries.CZ': 'République tchèque',
  'countries.DE': 'Allemagne',
  'countries.DJ': 'Djibouti',
  'countries.DK': 'Danemark',
  'countries.DM': 'Dominique',
  'countries.DO': 'République dominicaine',
  'countries.DZ': 'Algérie',
  'countries.EC': 'Équateur',
  'countries.EE': 'Estonie',
  'countries.EG': 'Égypte',
  'countries.EH': 'Sahara occidental',
  'countries.ER': 'Érythrée',
  'countries.ES': 'Espagne',
  'countries.ET': 'Éthiopie',
  'countries.EU': 'Europe',
  'countries.FI': 'Finlande',
  'countries.FJ': 'Fidji',
  'countries.FK': 'Îles Malouines',
  'countries.FM': 'Micronésie',
  'countries.FO': 'Îles Féroé',
  'countries.FR': 'France',
  'countries.GA': 'Gabon',
  'countries.GB': 'Royaume-Uni',
  'countries.GD': 'Grenade',
  'countries.GE': 'Géorgie',
  'countries.GF': 'Guyane française',
  'countries.GG': 'Guernesey',
  'countries.GH': 'Ghana',
  'countries.GI': 'Gibraltar',
  'countries.GL': 'Groenland',
  'countries.GM': 'Gambie',
  'countries.GN': 'Guinée',
  'countries.GP': 'Guadeloupe',
  'countries.GQ': 'Guinée équatoriale',
  'countries.GR': 'Grèce',
  'countries.GS': 'Géorgie du Sud et les îles Sandwich du Sud',
  'countries.GT': 'Guatemala',
  'countries.GU': 'Guam',
  'countries.GW': 'Guinée-Bissau',
  'countries.GY': 'Guyana',
  'countries.HK': 'Hong Kong',
  'countries.HM': 'Îles Heard-et-MacDonald',
  'countries.HN': 'Honduras',
  'countries.HR': 'Croatie',
  'countries.HT': 'Haïti',
  'countries.HU': 'Hongrie',
  'countries.ID': 'Indonésie',
  'countries.IE': 'Irlande',
  'countries.IL': 'Israël',
  'countries.IM': 'Île de Man',
  'countries.IN': 'Inde',
  'countries.IO': 'Territoire britannique de l’océan Indien',
  'countries.IQ': 'Irak',
  'countries.IR': 'Iran',
  'countries.IS': 'Islande',
  'countries.IT': 'Italie',
  'countries.JE': 'Jersey',
  'countries.JM': 'Jamaïque',
  'countries.JO': 'Jordanie',
  'countries.JP': 'Japon',
  'countries.KE': 'Kenya',
  'countries.KG': 'Kirghizistan',
  'countries.KH': 'Cambodge',
  'countries.KI': 'Kiribati',
  'countries.KM': 'Comores',
  'countries.KN': 'Saint-Christophe-et-Niévès',
  'countries.KP': 'Corée',
  'countries.KR': 'Corée',
  'countries.KW': 'Koweït',
  'countries.KY': 'Îles Caïmans',
  'countries.KZ': 'Kazakhstan',
  'countries.LA': 'République démocratique populaire lao',
  'countries.LB': 'Liban',
  'countries.LC': 'Sainte-Lucie',
  'countries.LI': 'Liechtenstein',
  'countries.LK': 'Sri Lanka',
  'countries.LR': 'Libéria',
  'countries.LS': 'Lesotho',
  'countries.LT': 'Lituanie',
  'countries.LU': 'Luxembourg',
  'countries.LV': 'Lettonie',
  'countries.LY': 'Jamahiriya arabe libyenne',
  'countries.MA': 'Maroc',
  'countries.MC': 'Monaco',
  'countries.MD': 'Moldavie',
  'countries.ME': 'Monténégro',
  'countries.MF': 'Saint-Martin (partie française)',
  'countries.MG': 'Madagascar',
  'countries.MH': 'Îles Marshall',
  'countries.MK': 'Macédoine',
  'countries.ML': 'Mali',
  'countries.MM': 'Myanmar',
  'countries.MN': 'Mongolie',
  'countries.MO': 'Macao',
  'countries.MP': 'Îles Mariannes du Nord',
  'countries.MQ': 'Martinique',
  'countries.MR': 'Mauritanie',
  'countries.MS': 'Montserrat',
  'countries.MT': 'Malte',
  'countries.MU': 'Maurice',
  'countries.MV': 'Maldives',
  'countries.MW': 'Malawi',
  'countries.MX': 'Mexique',
  'countries.MY': 'Malaisie',
  'countries.MZ': 'Mozambique',
  'countries.NA': 'Namibie',
  'countries.NC': 'Nouvelle-Calédonie',
  'countries.NE': 'Niger',
  'countries.NF': 'Île Norfolk',
  'countries.NG': 'Nigéria',
  'countries.NI': 'Nicaragua',
  'countries.NL': 'Pays-Bas',
  'countries.NO': 'Norvège',
  'countries.NP': 'Népal',
  'countries.NR': 'Nauru',
  'countries.NU': 'Niue',
  'countries.NZ': 'Nouvelle-Zélande',
  'countries.O1': 'Autre pays',
  'countries.OM': 'Oman',
  'countries.PA': 'Panama',
  'countries.PE': 'Pérou',
  'countries.PF': 'Polynésie française',
  'countries.PG': 'Papouasie-Nouvelle-Guinée',
  'countries.PH': 'Philippines',
  'countries.PK': 'Pakistan',
  'countries.PL': 'Pologne',
  'countries.PM': 'Saint-Pierre-et-Miquelon',
  'countries.PN': 'Pitcairn',
  'countries.PR': 'Porto Rico',
  'countries.PS': 'Territoire palestinien',
  'countries.PT': 'Portugal',
  'countries.PW': 'Palaos',
  'countries.PY': 'Paraguay',
  'countries.QA': 'Qatar',
  'countries.RE': 'Réunion',
  'countries.RO': 'Roumanie',
  'countries.RS': 'Serbie',
  'countries.RU': 'Fédération de Russie',
  'countries.RW': 'Rwanda',
  'countries.SA': 'Arabie saoudite',
  'countries.SB': 'Îles Salomon',
  'countries.SC': 'Seychelles',
  'countries.SD': 'Soudan',
  'countries.SE': 'Suède',
  'countries.SG': 'Singapour',
  'countries.SH': 'Sainte-Hélène',
  'countries.SI': 'Slovénie',
  'countries.SJ': 'Svalbard et Île Jan Mayen',
  'countries.SK': 'Slovaquie',
  'countries.SL': 'Sierra Leone',
  'countries.SM': 'Saint-Marin',
  'countries.SN': 'Sénégal',
  'countries.SO': 'Somalie',
  'countries.SR': 'Suriname',
  'countries.ST': 'Sao Tomé-et-Principe',
  'countries.SV': 'El Salvador',
  'countries.SY': 'République arabe syrienne',
  'countries.SZ': 'Swaziland',
  'countries.TC': 'Îles Turks et Caïques',
  'countries.TD': 'Tchad',
  'countries.TF': 'Terres australes françaises',
  'countries.TG': 'Togo',
  'countries.TH': 'Thaïlande',
  'countries.TJ': 'Tadjikistan',
  'countries.TK': 'Tokelau',
  'countries.TL': 'Timor oriental',
  'countries.TM': 'Turkménistan',
  'countries.TN': 'Tunisie',
  'countries.TO': 'Tonga',
  'countries.TR': 'Turquie',
  'countries.TT': 'Trinité-et-Tobago',
  'countries.TV': 'Tuvalu',
  'countries.TW': 'Taïwan',
  'countries.TZ': 'Tanzanie',
  'countries.UA': 'Ukraine',
  'countries.UG': 'Ouganda',
  'countries.UM': 'Îles mineures éloignées des États-Unis',
  'countries.US': 'États-Unis',
  'countries.UY': 'Uruguay',
  'countries.UZ': 'Ouzbékistan',
  'countries.VA': 'Saint-Siège (État de la Cité du Vatican)',
  'countries.VC': 'Saint-Vincent-et-les Grenadines',
  'countries.VE': 'Venezuela',
  'countries.VG': 'Îles Vierges britanniques',
  'countries.VI': 'Îles Vierges américaines',
  'countries.VN': 'Vietnam',
  'countries.VU': 'Vanuatu',
  'countries.WF': 'Wallis et Futuna',
  'countries.WS': 'Samoa',
  'countries.YE': 'Yémen',
  'countries.YT': 'Mayotte',
  'countries.ZA': 'Afrique du Sud',
  'countries.ZM': 'Zambie',
  'countries.ZW': 'Zimbabwe',
  'nationalities.AF': 'Afghan',
  'nationalities.AX': "Îles d'Åland",
  'nationalities.AL': 'Albanais',
  'nationalities.DZ': 'Algérien',
  'nationalities.AS': 'Samoan américain',
  'nationalities.AD': 'Andorran',
  'nationalities.AO': 'Angolais',
  'nationalities.AI': 'Anguillais',
  'nationalities.AQ': 'Antarctique',
  'nationalities.AG': 'Antiguais',
  'nationalities.AR': 'Argentin',
  'nationalities.AM': 'Arménien',
  'nationalities.AW': 'Arubais',
  'nationalities.AU': 'Australien',
  'nationalities.AT': 'Autrichien',
  'nationalities.AZ': 'Azerbaïdjanais',
  'nationalities.BS': 'Bahamien',
  'nationalities.BH': 'Bahreïni',
  'nationalities.BD': 'Bangladais',
  'nationalities.BB': 'Barbadien',
  'nationalities.BY': 'Biélorusse',
  'nationalities.BE': 'Belge',
  'nationalities.BZ': 'Bélizien',
  'nationalities.BJ': 'Béninois',
  'nationalities.BM': 'Bermudien',
  'nationalities.BT': 'Bhoutanais',
  'nationalities.BO': 'Bolivien',
  'nationalities.BA': 'Bosnien',
  'nationalities.BW': 'Botswanais',
  'nationalities.BV': 'Île Bouvet',
  'nationalities.BR': 'Brésilien',
  'nationalities.IO': 'BIOT',
  'nationalities.BN': 'Brunéien',
  'nationalities.BG': 'Bulgare',
  'nationalities.BF': 'Burkinabé',
  'nationalities.BI': 'Burundais',
  'nationalities.KH': 'Cambodgien',
  'nationalities.CM': 'Camerounais',
  'nationalities.CA': 'Canadien',
  'nationalities.CV': 'Cap-verdien',
  'nationalities.KY': 'Caïmanais',
  'nationalities.CF': 'Centrafricain',
  'nationalities.TD': 'Tchadien',
  'nationalities.CL': 'Chilien',
  'nationalities.CN': 'Chinois',
  'nationalities.CX': 'Île Christmas',
  'nationalities.CC': 'Îles Cocos',
  'nationalities.CO': 'Colombien',
  'nationalities.KM': 'Comorien',
  'nationalities.CD': 'Congolais',
  'nationalities.CG': 'Congolais',
  'nationalities.CK': 'Îles Cook',
  'nationalities.CR': 'Costaricain',
  'nationalities.CI': 'Ivoirien',
  'nationalities.HR': 'Croate',
  'nationalities.CU': 'Cubain',
  'nationalities.CY': 'Chypriote',
  'nationalities.CZ': 'Tchèque',
  'nationalities.DK': 'Danois',
  'nationalities.DJ': 'Djiboutien',
  'nationalities.DM': 'Dominicain',
  'nationalities.DO': 'Dominicain',
  'nationalities.EC': 'Équatorien',
  'nationalities.EG': 'Égyptien',
  'nationalities.SV': 'Salvadorien',
  'nationalities.GQ': 'Équato-guinéen',
  'nationalities.ER': 'Érythréen',
  'nationalities.EE': 'Estonien',
  'nationalities.ET': 'Éthiopien',
  'nationalities.FK': 'Îles Malouines',
  'nationalities.FO': 'Îles Féroé',
  'nationalities.FJ': 'Fidjien',
  'nationalities.FI': 'Finlandais',
  'nationalities.FR': 'Français',
  'nationalities.GF': 'Guyanais',
  'nationalities.PF': 'Polynésien français',
  'nationalities.TF': 'Terres australes et antarctiques françaises',
  'nationalities.GA': 'Gabonais',
  'nationalities.GM': 'Gambien',
  'nationalities.GE': 'Géorgien',
  'nationalities.DE': 'Allemand',
  'nationalities.GH': 'Ghanéen',
  'nationalities.GI': 'Gibraltarien',
  'nationalities.GR': 'Grec',
  'nationalities.GL': 'Groenlandais',
  'nationalities.GD': 'Grenadien',
  'nationalities.GP': 'Guadeloupéen',
  'nationalities.GU': 'Guamien',
  'nationalities.GT': 'Guatémaltèque',
  'nationalities.GG': 'Guernesiais',
  'nationalities.GN': 'Guinéen',
  'nationalities.GW': 'Bissau-guinéen',
  'nationalities.GY': 'Guyanien',
  'nationalities.HT': 'Haïtien',
  'nationalities.HM': 'Île Heard-et-Îles MacDonald',
  'nationalities.VA': 'Vatican',
  'nationalities.HN': 'Hondurien',
  'nationalities.HK': 'Hongkongais',
  'nationalities.HU': 'Hongrois',
  'nationalities.IS': 'Islandais',
  'nationalities.IN': 'Indien',
  'nationalities.ID': 'Indonésien',
  'nationalities.IR': 'Iranien',
  'nationalities.IQ': 'Irakien',
  'nationalities.IE': 'Irlandais',
  'nationalities.IM': 'Manx',
  'nationalities.IL': 'Israélien',
  'nationalities.IT': 'Italien',
  'nationalities.JM': 'Jamaïcain',
  'nationalities.JP': 'Japonais',
  'nationalities.JE': 'Jerseyais',
  'nationalities.JO': 'Jordanien',
  'nationalities.KZ': 'Kazakh',
  'nationalities.KE': 'Kényan',
  'nationalities.KI': 'Kiribatien',
  'nationalities.KW': 'Koweïtien',
  'nationalities.KG': 'Kirghize',
  'nationalities.LA': 'Laotien',
  'nationalities.LV': 'Letton',
  'nationalities.LB': 'Libanais',
  'nationalities.LS': 'Basotho',
  'nationalities.LR': 'Libérien',
  'nationalities.LY': 'Libyen',
  'nationalities.LI': 'Liechtensteinois',
  'nationalities.LT': 'Lituanien',
  'nationalities.LU': 'Luxembourgeois',
  'nationalities.MO': 'Macanais',
  'nationalities.MK': 'Macédonien',
  'nationalities.MG': 'Malgache',
  'nationalities.MW': 'Malawien',
  'nationalities.MY': 'Malaisien',
  'nationalities.MV': 'Maldivien',
  'nationalities.ML': 'Malien',
  'nationalities.MT': 'Maltais',
  'nationalities.MH': 'Îles Marshallaises',
  'nationalities.MQ': 'Martiniquais',
  'nationalities.MR': 'Mauritanien',
  'nationalities.MU': 'Mauricien',
  'nationalities.YT': 'Mahorais',
  'nationalities.MX': 'Mexicain',
  'nationalities.FM': 'Micronésien',
  'nationalities.MD': 'Moldave',
  'nationalities.MC': 'Monégasque',
  'nationalities.MN': 'Mongol',
  'nationalities.ME': 'Monténégrin',
  'nationalities.MF': 'Saint-Martinois',
  'nationalities.MS': 'Montserratien',
  'nationalities.MA': 'Marocain',
  'nationalities.MZ': 'Mozambicain',
  'nationalities.MM': 'Birman',
  'nationalities.NA': 'Namibien',
  'nationalities.NR': 'Nauruan',
  'nationalities.NP': 'Népalais',
  'nationalities.NL': 'Néerlandais',
  'nationalities.AN': 'Antillais néerlandais',
  'nationalities.NC': 'Néo-Calédonien',
  'nationalities.NZ': 'Néo-Zélandais',
  'nationalities.NI': 'Nicaraguayen',
  'nationalities.NE': 'Nigérien',
  'nationalities.NG': 'Nigérian',
  'nationalities.NU': 'Niuéen',
  'nationalities.NF': 'Île Norfolk',
  'nationalities.KP': 'Nord-coréen',
  'nationalities.MP': 'Marianais du Nord',
  'nationalities.NO': 'Norvégien',
  'nationalities.OM': 'Omanais',
  'nationalities.PK': 'Pakistanais',
  'nationalities.PW': 'Palaosien',
  'nationalities.PS': 'Palestinien',
  'nationalities.PA': 'Panaméen',
  'nationalities.PG': 'Papouan',
  'nationalities.PY': 'Paraguayen',
  'nationalities.PE': 'Péruvien',
  'nationalities.PH': 'Philippin',
  'nationalities.PN': 'Îles Pitcairn',
  'nationalities.PL': 'Polonais',
  'nationalities.PT': 'Portugais',
  'nationalities.PR': 'Portoricain',
  'nationalities.QA': 'Qatari',
  'nationalities.RE': 'Réunionnais',
  'nationalities.RO': 'Roumain',
  'nationalities.RU': 'Russe',
  'nationalities.RW': 'Rwandais',
  'nationalities.SH': 'Sainte-Hélénienne',
  'nationalities.KN': 'Saint-Kitts-et-Nevisien',
  'nationalities.LC': 'Saint-Lucien',
  'nationalities.PM': 'Saint-Pierrais et Miquelonnais',
  'nationalities.VC': 'Saint-Vincentais',
  'nationalities.WS': 'Samoan',
  'nationalities.SM': 'Saint-Marinais',
  'nationalities.ST': 'Santoméen',
  'nationalities.SA': 'Saoudien',
  'nationalities.SN': 'Sénégalais',
  'nationalities.RS': 'Serbe',
  'nationalities.SC': 'Seychellois',
  'nationalities.SL': 'Sierra-Léonais',
  'nationalities.SG': 'Singapourien',
  'nationalities.SK': 'Slovaque',
  'nationalities.SI': 'Slovène',
  'nationalities.SB': 'Salomonais',
  'nationalities.SO': 'Somalien',
  'nationalities.ZA': 'Sud-Africain',
  'nationalities.GS': 'Géorgien du Sud et îles Sandwich du Sud',
  'nationalities.KR': 'Sud-Coréen',
  'nationalities.ES': 'Espagnol',
  'nationalities.LK': 'Sri-Lankais',
  'nationalities.SD': 'Soudanais',
  'nationalities.SR': 'Surinamais',
  'nationalities.SJ': 'Svalbard',
  'nationalities.SZ': 'Swazi',
  'nationalities.SE': 'Suédois',
  'nationalities.CH': 'Suisse',
  'nationalities.SY': 'Syrien',
  'nationalities.TW': 'Taïwanais',
  'nationalities.TJ': 'Tadjik',
  'nationalities.TZ': 'Tanzanien',
  'nationalities.TH': 'Thaïlandais',
  'nationalities.TL': 'Timorais',
  'nationalities.TG': 'Togolais',
  'nationalities.TK': 'Tokelau',
  'nationalities.TO': 'Tongan',
  'nationalities.TT': 'Trinidadien',
  'nationalities.TN': 'Tunisien',
  'nationalities.TR': 'Turc',
  'nationalities.TM': 'Turkmène',
  'nationalities.TC': 'Îles Turques-et-Caïques',
  'nationalities.TV': 'Tuvaluan',
  'nationalities.UG': 'Ougandais',
  'nationalities.UA': 'Ukrainien',
  'nationalities.AE': 'Émirati',
  'nationalities.GB': 'Britannique',
  'nationalities.US': 'Américain',
  'nationalities.UM': 'Îles mineures éloignées des États-Unis',
  'nationalities.UY': 'Uruguayen',
  'nationalities.UZ': 'Ouzbek',
  'nationalities.VU': 'Vanuatais',
  'nationalities.VE': 'Vénézuélien',
  'nationalities.VN': 'Vietnamien',
  'nationalities.VG': 'Îles Vierges britanniques',
  'nationalities.VI': 'Îles Vierges américaines',
  'nationalities.WF': 'Wallis-et-Futuna',
  'nationalities.EH': 'Sahraoui',
  'nationalities.YE': 'Yéménite',
  'nationalities.ZM': 'Zambien',
  'nationalities.ZW': 'Zimbabwéen',
  'month.1': 'Janvier',
  'month.2': 'Février',
  'month.3': 'Mars',
  'month.4': 'Avril',
  'month.5': 'Mai',
  'month.6': 'Juin',
  'month.7': 'Juillet',
  'month.8': 'Août',
  'month.9': 'Septembre',
  'month.10': 'Octobre',
  'month.11': 'Novembre',
  'month.12': 'Décembre',
  'filters.art.price.0.label': '0',
  'filters.art.price.0.helper': '0',
  'filters.art.price.100.label': ' ',
  'filters.art.price.100.helper': '100',
  'filters.art.price.200.label': '200',
  'filters.art.price.200.helper': '200',
  'filters.art.price.300.label': ' ',
  'filters.art.price.300.helper': '300',
  'filters.art.price.500.label': '500',
  'filters.art.price.500.helper': '500',
  'filters.art.price.1000.label': ' ',
  'filters.art.price.1000.helper': '1000',
  'filters.art.price.2000.label': '2000',
  'filters.art.price.2000.helper': '2000',
  'filters.art.price.5000.label': ' ',
  'filters.art.price.5000.helper': '5000',
  'filters.art.price.10000.label': ' ',
  'filters.art.price.10000.helper': '10000',
  'filters.art.price.UP.label': 'Max',
  'filters.art.price.UP.helper': 'Maximum',
  'filters.art.color.colourful': 'Coloré',
  'filters.art.color.black-white': 'Noir & Blanc',
  'filters.art.color.white': 'Blanc',
  'filters.art.color.grey': 'Gris',
  'filters.art.color.black': 'Noir',
  'filters.art.color.maroon-neutral': 'Marron neutre',
  'filters.art.color.orange': 'Orange',
  'filters.art.color.yellow': 'Jaune',
  'filters.art.color.green': 'Vert',
  'filters.art.color.blue': 'Bleu',
  'filters.art.color.purple': 'Violet',
  'filters.art.color.pink': 'Rose',
  'filters.art.color.red': 'Rouge',
  'filters.art.medium.collage': 'Collage',
  'filters.art.medium.digital': 'Numérique',
  'filters.art.medium.drawings': 'Dessin',
  'filters.art.medium.installation': 'Installation',
  'filters.art.medium.paintings': 'Peinture',
  'filters.art.medium.photography': 'Photographie',
  'filters.art.medium.prints': 'Edition',
  'filters.art.medium.sculpture': 'Sculpture',
  'filters.art.subMedium.photo': 'Photo',
  'filters.art.subMedium.mixed-media-2': 'Technique mixte',
  'filters.art.subMedium.oil': 'Huile',
  'filters.art.subMedium.digital': 'Digital',
  'filters.art.subMedium.screenprint': 'Sérigraphie',
  'filters.art.subMedium.transfer': 'Transfert',
  'filters.art.subMedium.fabric': 'Tissu',
  'filters.art.subMedium.fabric-1': 'Tissu',
  'filters.art.subMedium.mixed-media-1': 'Technique mixte',
  'filters.art.subMedium.paper-1': 'Papier',
  'filters.art.subMedium.chalk': 'Craie',
  'filters.art.subMedium.charcoal': 'Fusain',
  'filters.art.subMedium.ink': 'Encre',
  'filters.art.subMedium.ink-1': 'Encre',
  'filters.art.subMedium.pastel': 'Pastel',
  'filters.art.subMedium.pencil': 'Crayon',
  'filters.art.subMedium.acrylic': 'Acrylique',
  'filters.art.subMedium.airbrush': 'Aérographe',
  'filters.art.subMedium.gouache': 'Gouache',
  'filters.art.subMedium.tempera': 'Tempera',
  'filters.art.subMedium.watercolour': 'Aquarelle',
  'filters.art.subMedium.black-white': 'Noir & Blanc',
  'filters.art.subMedium.crayon': 'Crayon',
  'filters.art.subMedium.colour': 'Couleur',
  'filters.art.subMedium.digital-1': 'Digital',
  'filters.art.subMedium.mixed-media': 'Technique mixte',
  'filters.art.subMedium.encaustic': 'Encaustique',
  'filters.art.subMedium.etching': 'Gravure',
  'filters.art.subMedium.lino': 'Lino',
  'filters.art.subMedium.lithograph': 'Lithographie',
  'filters.art.subMedium.marble': 'Marbre',
  'filters.art.subMedium.mixed-media-3': 'Technique mixte',
  'filters.art.subMedium.mono': 'Mono',
  'filters.art.subMedium.woodcut': 'Gravure sur bois',
  'filters.art.subMedium.bronze': 'Bronze',
  'filters.art.subMedium.ceramic': 'Céramique',
  'filters.art.subMedium.clay': 'Argile',
  'filters.art.subMedium.enamel': 'Email',
  'filters.art.subMedium.glass': 'Verre',
  'filters.art.subMedium.kinetic': 'Cinétique',
  'filters.art.subMedium.metal': 'Métal',
  'filters.art.subMedium.paper': 'Papier',
  'filters.art.subMedium.pinhole': 'Sténopé',
  'filters.art.subMedium.plastic': 'Plastique',
  'filters.art.subMedium.resin': 'Résine',
  'filters.art.subMedium.vector': 'Vectoriel',
  'filters.art.subMedium.wood': 'Bois',
  'filters.art.subMedium.stone': 'Pierre',
  'filters.art.stage.new': 'Nouveau et à suivre',
  'filters.art.stage.emerging': 'Emergent',
  'filters.art.stage.established': 'Confirmé',
  'filters.art.orientation.L': 'Paysage',
  'filters.art.orientation.P': 'Portrait',
  'filters.art.orientation.S': 'Carré',
  'filters.art.size.S.label': 'S',
  'filters.art.size.S.helper': "Le côté le plus long mesure jusqu'à {value}",
  'filters.art.size.M.label': 'M',
  'filters.art.size.M.helper': "Le côté le plus long mesure jusqu'à {value}",
  'filters.art.size.L.label': 'L',
  'filters.art.size.L.helper': "Le côté le plus long mesure jusqu'à {value}",
  'filters.art.size.XL.label': 'XL',
  'filters.art.size.XL.helper': "Le côté le plus long mesure jusqu'à {value}",
  'filters.art.size.XXL.label': 'XXL',
  'filters.art.size.XXL.helper': 'Le côté le plus long mesure plus de {value}',
  'filters.art.style.abstract': 'Abstrait',
  'filters.art.style.abstract-expressionism': 'Expressionnisme abstrait',
  'filters.art.style.abstract-marble': 'Marbre',
  'filters.art.style.art-deco': 'Art déco',
  'filters.art.style.cartoon': 'Dessin animé',
  'filters.art.style.conceptual': 'Conceptuel',
  'filters.art.style.colour-field': 'Colour field',
  'filters.art.style.cubism': 'Cubisme',
  'filters.art.style.geometric-abstract': 'Abstraction géométrique',
  'filters.art.style.dada': 'Dada',
  'filters.art.style.expressionistic': 'Expressioniste',
  'filters.art.style.graphic': 'Graphique',
  'filters.art.style.minimalistic': 'Minimaliste',
  'filters.art.style.mosaic': 'Mosaïque',
  'filters.art.style.figurative': 'Figuratif',
  'filters.art.style.geometric': 'Géometrique',
  'filters.art.style.illustrative': 'Illustration',
  'filters.art.style.impressionistic': 'Impressioniste',
  'filters.art.style.photorealism': 'Réalisme photographique',
  'filters.art.style.pop-art': 'Pop Art',
  'filters.art.style.realistic': 'Réaliste',
  'filters.art.style.stencil': 'Pochoir',
  'filters.art.style.street-art': 'Street Art',
  'filters.art.style.surrealist': 'Surréaliste',
  'filters.art.style.other': 'Autre',
  'filters.art.subject.architecture': 'Architecture',
  'filters.art.subject.botanical': 'Botanique',
  'filters.art.subject.celebrities': 'Célébrités',
  'filters.art.subject.documentary': 'Documentaire',
  'filters.art.subject.other': 'Autre',
  'filters.art.subject.science-technology': 'Science & Technologie',
  'filters.art.subject.seascapes': 'Paysages marins',
  'filters.art.subject.transport-auto': 'Transport & Auto',
  'filters.art.subject.typography': 'Typographie',
  'filters.art.subject.urban': 'Urbain',
  'filters.art.subject.maps': 'Cartes',
  'filters.art.subject.fantasy': 'Fantastique',
  'filters.art.subject.food': 'Nourriture',
  'filters.art.subject.abstract': 'Abstrait',
  'filters.art.subject.animals': 'Animaux',
  'filters.art.subject.cityscapes': 'Paysages urbains',
  'filters.art.subject.fashion': 'Mode',
  'filters.art.subject.landscapes': 'Paysages',
  'filters.art.subject.music': 'Musique',
  'filters.art.subject.nature': 'Nature',
  'filters.art.subject.nudes': 'Nus',
  'filters.art.subject.politics': 'Politique',
  'filters.art.subject.portraits': 'Portraits',
  'filters.art.subject.spirituality': 'Spiritualité',
  'filters.art.subject.sports': 'Sports',
  'filters.art.subject.still-life': 'Nature morte',
  'filters.art.subject.underwater': 'Aquatique',
  'filters.art.subject.wildlife': 'Faune',
  'filters.art.subSubject.bird': 'Oiseau',
  'filters.art.subSubject.cat': 'Chat',
  'filters.art.subSubject.cow': 'Vache',
  'filters.art.subSubject.dog': 'Chien',
  'filters.art.subSubject.fish': 'Poisson',
  'filters.art.subSubject.horse': 'Cheval',
  'filters.art.subSubject.hong-kong': 'Hong Kong',
  'filters.art.subSubject.london-1': 'Londres',
  'filters.art.subSubject.new-york-1': 'New York',
  'filters.art.subSubject.paris': 'Paris',
  'filters.art.subSubject.singapore': 'Singapour',
  'filters.art.subSubject.beach': 'Plage',
  'filters.art.subSubject.rural': 'Campagne',
  'filters.art.subSubject.sky': 'Ciel',
  'filters.art.subSubject.london': 'Londres',
  'filters.art.subSubject.new-york': 'New York',
  'filters.art.subSubject.flower': 'Fleurs',
  'filters.art.subSubject.tree': 'Arbres',
  'filters.art.subSubject.leaves': 'Feuilles',
  'filters.art.subSubject.female': 'Femme',
  'filters.art.subSubject.male': 'Homme',
  'filters.art.subSubject.religion': 'Religion',
  'filters.art.subSubject.athletics': 'Athlétisme',
  'filters.art.subSubject.cycling': 'Cyclisme',
  'filters.art.subSubject.football': 'Football',
  'filters.art.subSubject.flower-1': 'Fleurs',
  'filters.art.subSubject.food': 'Nourriture',
  'filters.art.subSubject.airplane': 'Avions',
  'filters.art.subSubject.bike': 'Vélos',
  'filters.art.subSubject.car': 'Voiture',
  'filters.art.subSubject.ship': 'Navire',
  'filters.art.subSubject.train': 'Trains',
  'filters.art.subSubject.yacht': 'Yacht',
  'filters.art.width.UP.label': 'Max',
  'filters.art.width.UP.helper': 'Maximum',
  'filters.art.height.UP.label': 'Max',
  'filters.art.height.UP.helper': 'Maximum',
  'filters.art.breadcrumbs.medium.collage': 'Collage',
  'filters.art.breadcrumbs.medium.digital': 'Numérique',
  'filters.art.breadcrumbs.medium.drawings': 'Dessin',
  'filters.art.breadcrumbs.medium.installation': 'Installation',
  'filters.art.breadcrumbs.medium.paintings': 'Peinture',
  'filters.art.breadcrumbs.medium.photography': 'Photographie',
  'filters.art.breadcrumbs.medium.prints': 'Edition',
  'filters.art.breadcrumbs.medium.sculpture': 'Sculpture',
  'filters.art.breadcrumbs.style.abstract': 'Abstrait',
  'filters.art.breadcrumbs.style.abstract-expressionism': 'Expressionnisme abstrait',
  'filters.art.breadcrumbs.style.abstract-marble': 'Marbre',
  'filters.art.breadcrumbs.style.art-deco': 'Art déco',
  'filters.art.breadcrumbs.style.cartoon': 'Dessin animé',
  'filters.art.breadcrumbs.style.conceptual': 'Conceptuel',
  'filters.art.breadcrumbs.style.colour-field': 'Colour field',
  'filters.art.breadcrumbs.style.cubism': 'Cubisme',
  'filters.art.breadcrumbs.style.geometric-abstract': 'Abstraction géométrique',
  'filters.art.breadcrumbs.style.dada': 'Dada',
  'filters.art.breadcrumbs.style.expressionistic': 'Expressioniste',
  'filters.art.breadcrumbs.style.graphic': 'Graphique',
  'filters.art.breadcrumbs.style.minimalistic': 'Minimaliste',
  'filters.art.breadcrumbs.style.mosaic': 'Mosaïque',
  'filters.art.breadcrumbs.style.figurative': 'Figuratif',
  'filters.art.breadcrumbs.style.geometric': 'Géometrique',
  'filters.art.breadcrumbs.style.illustrative': 'Illustration',
  'filters.art.breadcrumbs.style.impressionistic': 'Impressioniste',
  'filters.art.breadcrumbs.style.photorealism': 'Réalisme photographique',
  'filters.art.breadcrumbs.style.pop-art': 'Pop Art',
  'filters.art.breadcrumbs.style.realistic': 'Réaliste',
  'filters.art.breadcrumbs.style.stencil': 'Pochoir',
  'filters.art.breadcrumbs.style.street-art': 'Street Art',
  'filters.art.breadcrumbs.style.surrealist': 'Surréaliste',
  'filters.art.breadcrumbs.style.other': 'Autre',
  'filters.collection.medium.collage': 'Collage',
  'filters.collection.medium.drawings': 'Dessin',
  'filters.collection.medium.installation': 'Installation',
  'filters.collection.medium.paintings': 'Peinture',
  'filters.collection.medium.photography': 'Photographie',
  'filters.collection.medium.prints': 'Edition',
  'filters.collection.medium.sculpture': 'Sculpture',
  'filters.collection.style.abstract': 'Abstrait',
  'filters.collection.style.art-deco': 'Art déco',
  'filters.collection.style.cartoon': 'Dessin animé',
  'filters.collection.style.conceptual': 'Conceptuel',
  'filters.collection.style.cubism': 'Cubisme',
  'filters.collection.style.dada': 'Dada',
  'filters.collection.style.expressionistic': 'Expressioniste',
  'filters.collection.style.minimalistic': 'Minimaliste',
  'filters.collection.style.figurative': 'Figuratif',
  'filters.collection.style.geometric': 'Geometrique',
  'filters.collection.style.illustrative': 'Illustration',
  'filters.collection.style.impressionistic': 'Impressioniste',
  'filters.collection.style.pop-art': 'Pop Art',
  'filters.collection.style.realistic': 'Réaliste',
  'filters.collection.style.street-art': 'Street Art',
  'filters.collection.style.surrealist': 'Suréaliste',
  'filters.collection.subject.architecture': 'Architecture',
  'filters.collection.subject.botanical': 'Botanique',
  'filters.collection.subject.celebrities': 'Cékébrités',
  'filters.collection.subject.documentary': 'Documentaire',
  'filters.collection.subject.science-technology': 'Science & Technologie',
  'filters.collection.subject.seascapes': 'Paysages marins',
  'filters.collection.subject.transport-auto': 'Transport & Automobile',
  'filters.collection.subject.typography': 'Typographie',
  'filters.collection.subject.urban': 'Urbain',
  'filters.collection.subject.maps': 'Cartes',
  'filters.collection.subject.food': 'Nourriture',
  'filters.collection.subject.animals': 'Animaux',
  'filters.collection.subject.cityscapes': 'Paysages urbains',
  'filters.collection.subject.fashion': 'Mode',
  'filters.collection.subject.landscapes': 'Paysages',
  'filters.collection.subject.nudes': 'Nus',
  'filters.collection.subject.plants-flowers': 'Plantes & Fleurs',
  'filters.collection.subject.portraits': 'Portraits',
  'filters.collection.subject.spirituality': 'Spiritualité',
  'filters.collection.subject.sports': 'Sports',
  'filters.collection.subject.still-life': 'Nature morte',
  'filters.collection.breadcrumbs.medium.collage': 'Collage',
  'filters.collection.breadcrumbs.medium.drawings': 'Dessin',
  'filters.collection.breadcrumbs.medium.installation': 'Installation',
  'filters.collection.breadcrumbs.medium.paintings': 'Peinture',
  'filters.collection.breadcrumbs.medium.photography': 'Photographie',
  'filters.collection.breadcrumbs.medium.prints': 'Edition',
  'filters.collection.breadcrumbs.medium.sculpture': 'Sculpture',
  'filters.collection.breadcrumbs.style.abstract': 'Abstrait',
  'filters.collection.breadcrumbs.style.art-deco': 'Art déco',
  'filters.collection.breadcrumbs.style.cartoon': 'Dessin animé',
  'filters.collection.breadcrumbs.style.conceptual': 'Conceptuel',
  'filters.collection.breadcrumbs.style.cubism': 'Cubisme',
  'filters.collection.breadcrumbs.style.dada': 'Dada',
  'filters.collection.breadcrumbs.style.expressionistic': 'Expressioniste',
  'filters.collection.breadcrumbs.style.minimalistic': 'Minimaliste',
  'filters.collection.breadcrumbs.style.figurative': 'Figuratif',
  'filters.collection.breadcrumbs.style.geometric': 'Geometrique',
  'filters.collection.breadcrumbs.style.illustrative': 'Illustration',
  'filters.collection.breadcrumbs.style.impressionistic': 'Impressioniste',
  'filters.collection.breadcrumbs.style.pop-art': 'Pop Art',
  'filters.collection.breadcrumbs.style.realistic': 'Réaliste',
  'filters.collection.breadcrumbs.style.street-art': 'Street Art',
  'filters.collection.breadcrumbs.style.surrealist': 'Suréaliste',
  'filters.artist.medium.collagists': 'Artistes collage',
  'filters.artist.medium.digital': 'Artistes digitaux',
  'filters.artist.medium.draftspersons': 'Dessinateurs',
  'filters.artist.medium.installation': 'Artistes installation',
  'filters.artist.medium.painters': 'Peintres',
  'filters.artist.medium.photographers': 'Photographes',
  'filters.artist.medium.printmakers': 'Graveurs',
  'filters.artist.medium.sculptors': 'Sculpteurs',
  'filters.artist.style.abstract': 'Abstrait',
  'filters.artist.style.abstract-expressionism': 'Expressionnisme abstrait',
  'filters.artist.style.abstract-marble': 'Marbre',
  'filters.artist.style.art-deco': 'Art déco',
  'filters.artist.style.cartoon': 'Dessin animé',
  'filters.artist.style.conceptual': 'Conceptuel',
  'filters.artist.style.colour-field': 'Colour field',
  'filters.artist.style.cubism': 'Cubisme',
  'filters.artist.style.geometric-abstract': 'Abstraction géométrique',
  'filters.artist.style.dada': 'Dada',
  'filters.artist.style.expressionistic': 'Expressioniste',
  'filters.artist.style.graphic': 'Graphique',
  'filters.artist.style.minimalistic': 'Minimaliste',
  'filters.artist.style.mosaic': 'Mosaïque',
  'filters.artist.style.figurative': 'Figuratif',
  'filters.artist.style.geometric': 'Géometrique',
  'filters.artist.style.illustrative': 'Illustration',
  'filters.artist.style.impressionistic': 'Impressioniste',
  'filters.artist.style.photorealism': 'Réalisme photographique',
  'filters.artist.style.pop-art': 'Pop Art',
  'filters.artist.style.realistic': 'Réaliste',
  'filters.artist.style.stencil': 'Pochoir',
  'filters.artist.style.street-art': 'Street Art',
  'filters.artist.style.surrealist': 'Surréaliste',
  'filters.artist.region.african': 'Africain',
  'filters.artist.region.north-american': 'Nord-Américain',
  'filters.artist.region.latin-american': 'Latino-Américain',
  'filters.artist.region.caribbean': 'Caribéen',
  'filters.artist.region.european': 'Européen',
  'filters.artist.region.scandinavian': 'Scandinave',
  'filters.artist.region.easter-european': "Européen de l'Est",
  'filters.artist.region.asian': 'Asiatique',
  'filters.artist.region.south-asian': 'Sud-Asiatique',
  'filters.artist.region.middle-eastern': 'Moyen-Orient',
  'filters.artist.region.oceanian': 'Australien et Océanien',
  'filters.artist.gender.M': 'H',
  'filters.artist.gender.F': 'F',
  'filters.artist.stage.new': 'Nouveau et à suivre',
  'filters.artist.stage.emerging': 'Emergent',
  'filters.artist.stage.established': 'Confirmé',
  'filters.artist.breadcrumbs.medium.collagists': 'Artistes collage',
  'filters.artist.breadcrumbs.medium.digital': 'Artistes digitaux',
  'filters.artist.breadcrumbs.medium.draftspersons': 'Dessinateurs',
  'filters.artist.breadcrumbs.medium.installation': 'Artistes installation',
  'filters.artist.breadcrumbs.medium.painters': 'Peintres',
  'filters.artist.breadcrumbs.medium.photographers': 'Photographes',
  'filters.artist.breadcrumbs.medium.printmakers': 'Graveurs',
  'filters.artist.breadcrumbs.medium.sculptors': 'Sculpteurs',
  'filters.artist.breadcrumbs.style.abstract': 'Abstrait',
  'filters.artist.breadcrumbs.style.abstract-expressionism': 'Expressionnisme abstrait',
  'filters.artist.breadcrumbs.style.abstract-marble': 'Marbre',
  'filters.artist.breadcrumbs.style.art-deco': 'Art déco',
  'filters.artist.breadcrumbs.style.cartoon': 'Dessin animé',
  'filters.artist.breadcrumbs.style.conceptual': 'Conceptuel',
  'filters.artist.breadcrumbs.style.colour-field': 'Colour field',
  'filters.artist.breadcrumbs.style.cubism': 'Cubisme',
  'filters.artist.breadcrumbs.style.geometric-abstract': 'Abstraction géométrique',
  'filters.artist.breadcrumbs.style.dada': 'Dada',
  'filters.artist.breadcrumbs.style.expressionistic': 'Expressioniste',
  'filters.artist.breadcrumbs.style.graphic': 'Graphique',
  'filters.artist.breadcrumbs.style.minimalistic': 'Minimaliste',
  'filters.artist.breadcrumbs.style.mosaic': 'Mosaïque',
  'filters.artist.breadcrumbs.style.figurative': 'Figuratif',
  'filters.artist.breadcrumbs.style.geometric': 'Géometrique',
  'filters.artist.breadcrumbs.style.illustrative': 'Illustration',
  'filters.artist.breadcrumbs.style.impressionistic': 'Impressioniste',
  'filters.artist.breadcrumbs.style.photorealism': 'Réalisme photographique',
  'filters.artist.breadcrumbs.style.pop-art': 'Pop Art',
  'filters.artist.breadcrumbs.style.realistic': 'Réaliste',
  'filters.artist.breadcrumbs.style.stencil': 'Pochoir',
  'filters.artist.breadcrumbs.style.street-art': 'Street Art',
  'filters.artist.breadcrumbs.style.surrealist': 'Surréaliste',
  'filters.artist.singular.medium.collagists': 'Collagiste',
  'filters.artist.singular.medium.digital': 'Artiste digitaux',
  'filters.artist.singular.medium.draftspersons': 'Dessinateur',
  'filters.artist.singular.medium.installation': 'Artiste installation',
  'filters.artist.singular.medium.painters': 'Peintre',
  'filters.artist.singular.medium.photographers': 'Photographe',
  'filters.artist.singular.medium.printmakers': 'Graveur',
  'filters.artist.singular.medium.sculptors': 'Sculpteur',
  'brands.sohoHome': 'Soho Home',
  'common.yes': 'Oui',
  'common.no': 'Non',
  'common.ok': 'OK',
  'common.or': 'ou',
  'common.findOutMore': 'En savoir plus',
  'common.here': 'ici',
  'common.to': 'à',
  'common.by': 'par',
  'common.change': 'Changer',
  'common.edit': 'Modifier',
  'common.wishlist': 'Wishlist',
  'common.addToWishlist': 'Ajouter à la Wishlist',
  'common.removeFromWishlist': 'Retirer de la Wishlist',
  'common.yourWishlist': 'Votre Wishlist',
  'common.account': 'Compte',
  'common.apply': 'Appliquer',
  'common.remove': 'Supprimer',
  'common.read_more': 'Lire plus',
  'common.read_less': 'Lire moins',
  'common.show_more': 'Afficher plus',
  'common.show_less': 'Afficher moins',
  'common.learnMore': "Plus d'infos",
  'common.previous': 'Précédent',
  'common.next': 'Suivant',
  'common.share': 'Partager',
  'common.follow': 'Suivre',
  'common.unfollow': 'Ne plus suivre',
  'common.enquire': 'demander',
  'common.day': 'Jour',
  'common.month': 'Mois',
  'common.year': 'Année',
  'common.gender.M': 'Homme',
  'common.gender.F': 'Femme',
  'common.durations.month.full': 'mois',
  'common.durations.month.abbreviated': 'mo',
  'common.birthdayAbbreviated': 'Né(e) le',
  'common.timediff.lessThanMin': "moins d'une minute",
  'common.timediff.minutes': '{minutes, plural, one {il y a # minute} other {Il y a # minutes}}',
  'common.timediff.hours': '{hours, plural, one {Il y a # heure} other {Il y a # heures}}',
  'common.timediff.days': '{days, plural, one {il y a # jour} other {il y a # jours}}',
  'common.timediff.weeks': '{weeks, plural, one {il y a # semaine} other {il y a # semaines}}',
  'common.timediff.months': '{months, plural, one {Il y a # mois} other {il y a # mois}}',
  'common.timediff.moreThanYear': "plus d'un an",
  'common.you': 'vous',
  'common.objectByOwner': '{object} par {owner}',
  'common.byName': 'Par {name}',
  'common.byNameLowercase': 'par {name}',
  'common.byNameLowercaseLink': 'par <link>{name}</link>',
  'common.rssFeed': 'Flux RSS',
  'common.viewMore': 'Voir plus',
  'common.reviews': 'Avis sur Rise Art',
  'common.unitCm': '{value}cm',
  'common.unitIn': '{value}in',
  'common.artistName': '{name} Artiste',
  'components.activity.listTitle': 'Activité récente',
  'components.activity.trending.title': 'Les collectionneurs adorent',
  'components.activity.trending.subTitle': "Notre fil d'actualité en temps réel",
  'components.activity.trending.labels.product-sold': "vient d'être vendu",
  'components.activity.trending.labels.product-rental': "vient d'être loué",
  'components.activity.trending.labels.favorite-product': 'ajouté aux favoris',
  'components.activity.trending.labels.cart-add': 'ajouté au panier',
  'components.activity.trending.labels.artist-shortlisted': 'nouvel artiste',
  'components.activity.trending.labels.follow-shortlisted': 'suivi',
  'components.activity.renderers.cart-add': '{objectByOwner} a été ajouté au panier',
  'components.activity.renderers.cart-add-creator-me': '{objectByOwner} a été ajouté au panier',
  'components.activity.renderers.product-sold': '{objectByOwner} a été vendu',
  'components.activity.renderers.product-sold-creator-me': '{objectByOwner} a été vendu',
  'components.activity.renderers.product-rental': '{objectByOwner} a été loué',
  'components.activity.renderers.product-rental-creator-me': '{objectByOwner} a été loué',
  'components.activity.renderers.favorite-product': '{objectByOwner} a été ajouté aux favoris',
  'components.activity.renderers.favorite-product-creator-me':
    '{objectByOwner} a été ajouté aux favoris',
  'components.activity.renderers.follow-shortlisted': '{creatorName} suit désormais {object}',
  'components.activity.renderers.follow-shortlisted-creator-me': 'Vous suivez désormais {object}',
  'components.activity.renderers.product-available':
    '{creatorName} a rendu {object} disponible à la vente',
  'components.activity.renderers.product-available-creator-me':
    'Vous avez rendu {object} disponible à la vente',
  'components.activity.renderers.event-created': "{creatorName} a créé l'événement {object}",
  'components.activity.renderers.event-created-creator-me': "Vous avez créé l'événement {object}",
  'components.activity.renderers.event-updated': "{creatorName} a mis à jour l'événement {object}",
  'components.activity.renderers.event-updated-creator-me':
    "Vous avez mis à jour l'événement {object}",
  'components.activity.renderers.event-canceled': "{creatorName} a annulé l'événement {object}",
  'components.activity.renderers.event-canceled-creator-me':
    "Vous avez annulé l'événement {object}",
  'components.activity.renderers.event-rsvp-yes': '{creatorName} participe désormais à {object}',
  'components.activity.renderers.event-rsvp-yes-creator-me': 'Vous participez désormais à {object}',
  'components.activity.renderers.article-created': '{creatorName} a écrit un article {object}',
  'components.activity.renderers.article-created-creator-me': 'Vous avez écrit un article {object}',
  'components.activity.renderers.collection-created':
    '{creatorName} a créé une collection {object}',
  'components.activity.renderers.collection-created-creator-me':
    'Vous avez créé une collection {object}',
  'components.breadcrumbs.home': "Acheter de l'art en ligne",
  'components.breadcrumbs.blog': 'Edito',
  'components.breadcrumbs.guides': 'Guides',
  'components.breadcrumbs.art': 'Toutes les œuvres',
  'components.breadcrumbs.artist': 'Tous les Artistes',
  'components.breadcrumbs.collection': 'Toutes les collections',
  'components.breadcrumbs.activityList': 'Activité récente',
  'components.breadcrumbs.eventList': 'Evénements',
  'components.layout.recentlyViewed.long': 'Reprendre où vous vous êtes arrêté',
  'components.layout.recentlyViewed.short': 'Récemment consulté',
  'components.art.rent_for': 'Loué pour',
  'components.art.sold': 'Vendu',
  'components.art.sort': 'Trier',
  'components.art.about_author': 'À propos de {name}',
  'components.art.by': 'Par',
  'components.art.includesOption': 'Inclus',
  'components.art.addOption': 'Ajouter',
  'components.art.size_tooltip_heading': "Dimensions de l'œuvre",
  'components.art.size_tooltip_description':
    'Hauteur x Largeur x Profondeur (si renseignée), Pour les œuvres encadrées, vous pouvez ajouter environ 5 cm de chaque côté.',
  'components.art.artwork_by_artist': '{title} par {artist}',
  'components.art.addFrame': 'Ajouter un cadre',
  'components.art.framed': 'Encadré',
  'components.art.view_full_details': 'Voir tous les détails',
  'components.art.add_to_basket': 'Ajouter au panier',
  'components.art.rent': "Location d'œuvres d'art",
  'components.art.rent_monthly': 'Louer à partir de {rentPrice}/mois',
  'components.art.edition_of': 'Edition de {editionSize}',
  'components.art.edition_of_short': 'de {editionSize}',
  'components.art.instalments.infoText': 'à partir de {priceInInstalments}/mois',
  'components.art.instalments.hintTitle': 'Paiement en plusieurs fois',
  'components.art.instalments.hintDescription':
    "Payez votre œuvre d'art en mensualités sans intérêt à partir de {priceInInstalments}/mois.",
  'components.art.instalments.hintLink': 'En savoir plus.',
  'components.art.options.insuranceHintTitle': 'Garantie contre les dommages',
  'components.art.options.insuranceHintText':
    'Pour une petite cotisation mensuelle, nous réduisons le montant dont vous êtes responsable en cas de dommages accidentels ou de vol.',
  'components.art.options.rentHelpTitle': "Comment fonctionne la location d'une oeuvre d'art ?",
  'components.art.options.frame.black_frame': 'Cadre noir',
  'components.art.options.frame.walnut_frame': 'Cadre couleur noyer',
  'components.art.options.frame.white_frame': 'Cadre blanc',
  'components.art.full_details': 'Voir tous les détails',
  'components.art.noFrame': 'Non encadré',
  'components.art.unframed': 'Non encadré',
  'components.art.shipsFramed': 'Expédition avec cadre',
  'components.art.shipsFramedTooltipHeading': "L'œuvre est expédiée avec un cadre",
  'components.art.shipsFramedTooltipDescription':
    "Cette œuvre d'art est vendue avec un cadre. Pour plus d'informations sur le cadre, veuillez consulter les détails de l'œuvre d`'art.",
  'components.art.shipsReadyToHang': 'Prête à accrocher',
  'components.art.shipsReadyToHangTooltipHeading': "L'œuvre est prête à accrocher",
  'components.art.shipsReadyToHangTooltipDescription':
    "Cette œuvre est vendue prête à accrocher. Pour plus d'informations, veuillez consulter la description de l'œuvre.",
  'components.art.shipsFramedAndHang': 'Encadrée et prête à accrocher',
  'components.art.sold_out': "Désolé, cette œuvre d'art a été vendue",
  'components.art.is_rented': "Désolé, cette œuvre d'art est louée",
  'components.art.is_enquire':
    "Veuillez nous contacter pour connaître le prix et la disponibilité de l'œuvre",
  'components.art.enquire_work': 'Recevoir des informations',
  'components.art.commissionArtist': 'Commandez à cet artiste',
  'components.art.makeOffer': 'Faire une offre',
  'components.art.unavailable': "Cette œuvre d'art n'est pas disponible.",
  'components.art.unavailable_to_buy':
    "Si vous êtes intéressé par l'acquisition de cette œuvre, veuillez nous contacter.",
  'components.art.unavailable_in_store':
    "Cette œuvre d'art n'est actuellement pas disponible à l'achat dans cette devise. Vous pouvez acheter cette œuvre d'art en {availableCurrencies} {totalStores, plural, one {devise} other {devises}} en modifiant vos préférences en haut de la page.",
  'components.art.details.type': 'Type',
  'components.art.details.medium': 'Support',
  'components.art.details.style': 'Style',
  'components.art.details.subject': 'Sujet',
  'components.art.details.year': 'Année',
  'components.art.details.editionSize': 'Edition',
  'components.art.details.size': 'Dimensions',
  'components.art.details.readyToHang': 'Prête à accrocher',
  'components.art.details.framed': 'Encadrée',
  'components.art.details.signed': 'Signée',
  'components.art.details.materials': 'Materiaux',
  'components.art.details.shipping': 'Expédition',
  'components.art.details.viewInRoom': 'Prévisualiser dans une pièce',
  'components.art.details.viewInRoomTruncated': 'Dans une pièce',
  'components.art.art_types.original_art': 'Œuvre originale',
  'components.art.art_types.limited_edition': 'Édition limitée',
  'components.art.urgentMessages.original.heading': 'Œuvre originale',
  'components.art.urgentMessages.original.content':
    "Plus qu'une seule disponible ! Passez commande avant qu'elle ne disparaisse.",
  'components.art.urgentMessages.edition.heading': 'Foncez !',
  'components.art.urgentMessages.edition.content': 'Quantités limitées disponibles',
  'components.art.urgentMessages.editionLimited.heading': 'Ne passez pas à côté',
  'components.art.urgentMessages.editionLimited.content':
    'Il ne reste que {stock} exemplaire(s) de cette édition',
  'components.art.urgentMessages.cart.originalSingle.heading':
    "Passez commande avant qu'elle ne disparaisse:",
  'components.art.urgentMessages.cart.originalSingle.content':
    'Votre panier contient une œuvre originale avec une seule unité disponible.',
  'components.art.urgentMessages.cart.originalMultiple.heading':
    "Passez commande avant qu'elle ne disparaisse:",
  'components.art.urgentMessages.cart.originalMultiple.content':
    "Votre panier contient des œuvres originales. Il n'y en a plus qu'une de chaque disponible.",
  'components.art.urgentMessages.cart.editionLimited.heading': 'Ne passez pas à côté :',
  'components.art.urgentMessages.cart.editionLimited.content':
    'Votre panier contient des éditions avec un stock très limité.',
  'components.art.urgentMessages.cart.editionMultiple.heading': 'Ne passez pas à côté :',
  'components.art.urgentMessages.cart.editionMultiple.content':
    "Les œuvres d'art dans votre panier ne vous appartiennent pas tant que vous n'avez pas finalisé votre achat.",
  'components.art.product_info': "Détails de l'oeuvre",
  'components.art.need_to_know': 'À savoir',
  'components.art.additional_info': 'Informations supplémentaires',
  'components.art.related_content': 'Contenu lié',
  'components.art.shipsFromCountry': 'Expédié depuis {country}',
  'components.art.shipsFromShort': 'Expédié depuis {country} avant le {date}',
  'components.art.shipsFromLong': 'Expédié depuis {country} le {date} au plus tard.',
  'components.art.curatorAvatarHeading': "L'initié {name} dit :",
  'components.art.curatorHeading': 'Notre initié dit :',
  'components.art.viewProfile': 'Voir le profil',
  'components.art.need_help.title': "Besoin d'aide ?",
  'components.art.need_help.text':
    "Si vous souhaitez en savoir plus sur cette œuvre, parlez à l'un de nos curateurs et obtenez des conseils d'experts gratuitement.",
  'components.art.need_help.button': 'Contactez-nous',
  'components.art.requestMoreImages.title': 'Demander plus de photos',
  'components.art.requestMoreImages.titleTruncated': 'Plus de photos',
  'components.art.requestMoreImages.message':
    'Je souhaiterais recevoir plus de photos de cette œuvre',
  'components.art.arrangeViewing.title': 'Organiser une visite',
  'components.art.arrangeViewing.titleTruncated': 'Visite',
  'components.art.arrangeViewing.message':
    'Je souhaiterais organiser une visite pour présenter cette œuvre',
  'components.art.more_artwork_by': "Plus d'œuvres de {name}",
  'components.art.view_all_by': 'Voir toutes les œuvres de {name}',
  'components.art.similarArtworks': "Œuvres d'art similaires",
  'components.art.similarArtworksTruncated': "Œuvres d'art similaires",
  'components.art.similarCategoryLinks': "Autres œuvres d'art dans des styles similaires",
  'components.art.similarArtistLinks': 'Autres artistes dans le même domaine',
  'components.art.recentlyViewedArtworks': 'Œuvres récemment consultées',
  'components.art.delivery': 'Livraison',
  'components.art.commissionLabel': "Commander une œuvre d'art similaire",
  'components.art.commissionLabelAction': 'Apprendre comment',
  'components.art.returns': 'Retours gratuits',
  'components.art.returnsLabel': 'Retours gratuits sous 14 jours',
  'components.art.payInInstalments': 'Payer en plusieurs fois sans frais',
  'components.art.worldwideShipping': 'Livraison dans le monde entier',
  'components.art.kazoartProLeasing':
    '<strong>Professionnels:</strong> achetez cette œuvre en leasing',
  'components.art.findSimilarArtworks': "Trouver des œuvres d'art similaires",
  'components.art.gridNoArtworksDefault': "Aucune œuvre d'art trouvée.",
  'components.art.gridNoArtworks':
    "<p>Aucune œuvre d'art trouvée. Veuillez modifier votre recherche ou vos filtres.</p><p><strong>À LA RECHERCHE D'ARTISTES ?</strong> <a>Cliquez ici.</a></p>",
  'components.art.gridNoArtworksLinkTitle': 'CCliquez ici pour rechercher des artistes.',
  'components.art.trustedBy': 'Recommandé par des marques de renom, dont {link}',
  'components.art.gridView': 'Vue en grille',
  'components.art.detailsView': 'Vue de la galerie',
  'components.art.openDetailsView': 'Ouvrir dans la galerie',
  'components.art.viewInRoomBkgAlt': "Vue de l'intérieur d'une pièce",
  'components.artist.artist': 'Artiste',
  'components.artist.profile': 'À propos',
  'components.artist.cv': 'CV',
  'components.artist.cvHeader': 'C.V.',
  'components.artist.artworks': "{name} Œuvres d'art",
  'components.artist.studio': 'En Atelier',
  'components.artist.artistStudio': 'Atelier {name}',
  'components.artist.news': 'Actualités et expositions',
  'components.artist.artistNews': 'Actualités et expositions de {name}',
  'components.artist.featuredIn': 'Présenté dans',
  'components.artist.recentActivity': 'Activité récente',
  'components.artist.commissionTitle': 'Commandez une œuvre de {name}',
  'components.artist.commissionSubtitle':
    "Nous pouvons organiser et superviser la création d'une nouvelle œuvre spécialement pour vous.",
  'components.artist.findArtTitle': "Ce n'est pas ce que vous cherchiez ?",
  'components.artist.findArtSubtitle':
    "Trouvez l'œuvre d'art parfaite en utilisant nos filtres ou en {link} pour vous aider à la trouver.",
  'components.artist.findArtSubtitleLink': "discuter avec l'un de nos curateurs",
  'components.artist.findArtAction': 'Explorez notre catalogue',
  'components.artist.events': 'En exposition',
  'components.artist.viewCv': 'Voir le parcours',
  'components.artist.visitStudio': "Visiter l'atelier",
  'components.artist.bornIn': '{year} à {location}',
  'components.artist.bornInLabel': 'Né',
  'components.artist.livesLabel': 'Vit et travaille',
  'components.artist.education': 'Formation',
  'components.artist.awards': 'Récompenses',
  'components.artist.collections': 'Collections',
  'components.artist.soloExhibitions': 'Expositions individuelles sélectionnées',
  'components.artist.groupExhibitions': 'Expositions collectives sélectionnées',
  'components.artist.bibliography': 'Bibliographie',
  'components.artist.expandButtonLabel': 'liste complète',
  'components.artist.printCV': 'Imprimer le CV complet',
  'components.artist.noStudioFeed':
    "Il n'y a actuellement aucun article du journal d'atelier pour cet artiste.",
  'components.artist.noNews':
    "Il n'y a actuellement aucune actualité ni exposition pour cet artiste.",
  'components.artist.gridNoArtistsDefault': 'Aucun artiste trouvé.',
  'components.artist.gridNoArtists':
    "<p>Aucun artiste trouvé. Veuillez modifier votre recherche ou vos filtres.</p><p><strong>A LA RECHERCHE D'ŒUVRES D'ART ?</strong> <a>Cliquez ici.</a></p>",
  'components.artist.gridNoArtistsLinkTitle': "Cliquez ici pour rechercher des œuvres d'art.",
  'components.artist.unavailable': "Cet artiste n'est pas disponible.",
  'components.artist.careerStage.established': 'Artiste confirmé',
  'components.artist.careerStage.emerging': 'Artiste émergent',
  'components.artist.careerStage.new': 'Artiste à suivre',
  'components.event.type.soloShow': 'exposition individuelle',
  'components.event.type.artFair': "foire d'art",
  'components.event.type.talk': 'conférence',
  'components.event.type.groupShow': 'exposition collective',
  'components.event.type.other': 'autre',
  'components.event.listTitle': 'Evénements',
  'components.event.rsvpBtnLabel.line1': 'RS',
  'components.event.rsvpBtnLabel.line2': 'VP',
  'components.event.rsvpStatus.0': 'Non',
  'components.event.rsvpStatus.1': 'Oui',
  'components.event.rsvpStatus.2': 'Peut-être',
  'components.event.headings.description': 'Description',
  'components.event.headings.dateAndTime': 'Date et heure',
  'components.event.headings.address': 'Adresse',
  'components.event.headings.attendees': 'Participants',
  'components.event.headings.share': 'Partager cet événement',
  'components.event.addCalendar': 'Ajouter a calendrier',
  'components.event.visitSite': 'Visiter le site',
  'components.filters.showMore': 'Afficher plus de filtres',
  'components.filters.showLess': 'Afficher moins de filtres',
  'components.filters.clearFilters': 'Effacer',
  'components.filters.clearAll': 'Tout effacer',
  'components.filters.selectedCount': 'sélectionné(s)',
  'components.filters.rangeUnits': '{min} - {max} {units}',
  'components.filters.rangePrice': '{min} - {max} {currency}',
  'components.filters.price.label': 'Prix',
  'components.filters.size.label': 'Dimensions',
  'components.filters.orientation.label': 'Orientation',
  'components.filters.color.label': 'Couleur',
  'components.filters.color.defaultText': 'Toutes les couleurs',
  'components.filters.color.multiple': 'Couleurs sélectionnées',
  'components.filters.color.title': 'Sélectionner les couleurs',
  'components.filters.color.noOptions': 'Aucune couleur disponible',
  'components.filters.medium.label': 'Medium',
  'components.filters.medium.defaultText': 'Tous les mediums',
  'components.filters.medium.multiple': 'Mediums sélectionnés',
  'components.filters.medium.title': 'Sélectionner les mediums',
  'components.filters.medium.noOptions': 'Aucun medium disponible',
  'components.filters.style.label': 'Style',
  'components.filters.style.defaultText': 'Tous les styles',
  'components.filters.style.multiple': 'Styles sélectionnés',
  'components.filters.style.title': 'Sélectionner les styles',
  'components.filters.style.noOptions': 'Aucun style disponible',
  'components.filters.subject.label': 'Sujet',
  'components.filters.subject.defaultText': 'Tous les sujets',
  'components.filters.subject.multiple': 'Sujets sélectionnés',
  'components.filters.subject.title': 'Sélectionner les sujets',
  'components.filters.subject.noOptions': 'Aucun sujet disponible',
  'components.filters.gender.label': 'Genre',
  'components.filters.initial.label': 'Nom de famille',
  'components.filters.initial.defaultText': 'Tous les noms',
  'components.filters.initial.multiple': 'Noms sélectionnés',
  'components.filters.initial.title': 'Sélectionner les noms',
  'components.filters.initial.noOptions': 'Aucun nom disponible',
  'components.filters.ships.label': 'Expédition depuis',
  'components.filters.ships.defaultText': 'Tous les pays',
  'components.filters.ships.multiple': 'Pays sélectionnés',
  'components.filters.ships.title': 'Sélectionner les pays',
  'components.filters.ships.noOptions': 'Aucun pays disponible',
  'components.filters.stage.label': 'Étape de carrière',
  'components.filters.stage.defaultText': 'Toutes les étapes de carrière',
  'components.filters.stage.multiple': 'Étapes de carrière sélectionnées',
  'components.filters.stage.title': 'Sélectionner les étapes de carrière',
  'components.filters.stage.noOptions': 'Aucune étape de carrière disponible',
  'components.filters.region.label': 'Région',
  'components.filters.region.defaultText': 'Toutes les régions',
  'components.filters.region.multiple': 'Régions sélectionnées',
  'components.filters.region.title': 'Sélectionner les régions',
  'components.filters.region.noOptions': 'Aucune région disponible',
  'components.filters.born.label': 'Nationalité',
  'components.filters.born.defaultText': 'Toutes les nationalités',
  'components.filters.born.multiple': 'Nationalités sélectionnées',
  'components.filters.born.title': 'Sélectionner les nationalités',
  'components.filters.born.noOptions': 'Aucune nationalité disponible',
  'components.filters.height.label': 'Hauteur',
  'components.filters.width.label': 'Largeur',
  'components.filters.showFilters': 'Afficher les filtres',
  'components.filters.hideFilters': 'Masquer les filtres',
  'components.filters.breadcrumbs.artist': '{dimension2} {dimension1LowerCase}',
  'components.filters.breadcrumbs.art': '{dimension2} {dimension1LowerCase}',
  'components.filters.breadcrumbs.collection': '{dimension2} {dimension1LowerCase}',
  'components.filters.parentChildDimensions': '{dimension2} {dimension1}',
  'components.filters.mediumStyleLabel': '{style} {medium}',
  'components.cms.modules.homeimage.h3': 'Contemporain',
  'components.cms.modules.homeimage.h2': 'Art',
  'components.cms.modules.homeimage.h4': 'Sélectionné par des Experts',
  'components.cms.modules.homeimage.browse': 'EXPLOREZ',
  'components.cms.modules.homeimage.curated': 'NOTRE CATALOGUE',
  'components.cms.modules.homeimage.selection': "D'ŒUVRES D'ART",
  'components.cms.modules.homeimage.browsing': 'Vous regardez juste ?',
  'components.cms.modules.homeimage.curators': 'Nos curateurs vous accompagnent',
  'components.cms.modules.homeimage.by': 'par',
  'components.cms.modules.legal.effectiveDate': "Date d'entrée en vigueur",
  'components.cms.modules.legal.version': 'Version',
  'components.cms.modules.reviews.readAll': 'Lire tous nos avis',
  'components.share.facebook': 'Facebook',
  'components.share.twitter': 'Twitter',
  'components.share.email': 'E-mail',
  'components.share.whatsapp': 'Whatsapp',
  'components.share.pinterest': 'Pinterest',
  'components.share.options.facebook': 'Facebook',
  'components.share.options.twitter': 'Twitter',
  'components.share.options.email': 'E-mail',
  'components.share.options.whatsapp': 'Whatsapp',
  'components.share.options.pinterest': 'Pinterest',
  'components.share.options.messenger': 'Messenger',
  'components.share.options.link': 'Link',
  'components.share.messages.article.facebook': 'Découvrez {title} sur @riseart #RiseArt ',
  'components.share.messages.article.twitter': 'Découvrez {title} sur @riseart #RiseArt ',
  'components.share.messages.article.pinterest':
    '\n  Découvrez {title} sur @riseart #RiseArt\n\n  {link}\n  ',
  'components.share.messages.article.emailSubject': 'Découvrez {title} sur @riseart ',
  'components.share.messages.article.email': '\n  Découvrez {title} sur @riseart #RiseArt\n\n',
  'components.share.messages.article.whatsapp': 'Découvrez {title} sur {link} #RiseArt ',
  'components.share.messages.article.messenger': 'Découvrez {title} sur @riseart {link} #RiseArt ',
  'components.share.messages.article.link': 'Découvrez {title} sur @riseart {link} #RiseArt ',
  'components.share.messages.guide.facebook': 'Découvrez {title} sur @riseart #RiseArt ',
  'components.share.messages.guide.twitter': 'Découvrez {title} sur @riseart #RiseArt ',
  'components.share.messages.guide.pinterest':
    '\n  Découvrez {title} sur @riseart #RiseArt\n\n  {link}\n  ',
  'components.share.messages.guide.emailSubject': 'Découvrez {title} sur @riseart ',
  'components.share.messages.guide.email': '\n  Découvrez {title} sur @riseart #RiseArt\n\n',
  'components.share.messages.guide.whatsapp': 'Découvrez {title} sur @riseart {link} #RiseArt ',
  'components.share.messages.guide.messenger': 'Découvrez {title} sur @riseart {link} #RiseArt ',
  'components.share.messages.guide.link': 'Découvrez {title} sur @riseart {link} #RiseArt ',
  'components.share.messages.artwork_detail.facebook': 'Découvrez {name} de {artist} on Rise Art ',
  'components.share.messages.artwork_detail.twitter': 'Découvrez {name} de {artist} on Rise Art ',
  'components.share.messages.artwork_detail.pinterest':
    '\nDécouvrez {name} de {artist} on Rise Art\n\n{url}\n',
  'components.share.messages.artwork_detail.emailSubject':
    'Découvrez {name} de {artist} on Rise Art',
  'components.share.messages.artwork_detail.email':
    "\nDécouvrez cette œuvre d'art sur Rise Art et dites-moi ce que vous en pensez !\n\n{url}",
  'components.share.messages.artwork_detail.whatsapp': 'Découvrez {name} de {artist} on Rise Art ',
  'components.share.messages.artwork_detail.messenger': 'Découvrez {name} de {artist} on Rise Art ',
  'components.share.messages.artwork_detail.link': 'Découvrez {name} de {artist} on Rise Art ',
  'components.share.messages.collection_detail.facebook':
    'Découvrez cette collection de Rise Art : {name} ',
  'components.share.messages.collection_detail.twitter':
    "J'admire la collection {name} sur Rise Art #{name} ",
  'components.share.messages.collection_detail.pinterest': '\n{name} | Rise Art\n\n{url}\n',
  'components.share.messages.collection_detail.emailSubject':
    'Vous allez adorer cette collection de Rise Art : {name}',
  'components.share.messages.collection_detail.email':
    "\nBonjour !\n\nJ'apprécie la collection {name} sur Rise Art. Jetez-y un coup d'œil et dites-moi ce que vous en pensez.\n\nLink: {url}",
  'components.share.messages.collection_detail.whatsapp':
    'Découvrez cette collection de Rise Art : {name}',
  'components.share.messages.collection_detail.messenger':
    'Découvrez cette collection de Rise Art : {name}',
  'components.share.messages.collection_detail.link':
    'Découvrez cette collection de Rise Art : {name}',
  'components.share.messages.quiz_personality.facebook':
    'Ma personnalité artistique est {name}. Découvrir ce que cela signifie de mon style et de mes goûts artistiques avec le quiz de personnalité Rise Art',
  'components.share.messages.quiz_personality.twitter':
    'Ma personnalité artistique est {name}. Découvrir ce que cela signifie de mon style et de mes goûts artistiques avec le quiz de personnalité Rise Art #DécouvrezVotreStyle',
  'components.share.messages.quiz_personality.pinterest':
    'Ma personnalité artistique est {name}. Découvrir ce que cela signifie de mon style et de mes goûts artistiques avec le quiz de personnalité Rise Art',
  'components.share.messages.quiz_personality.emailSubject':
    'Ma personnalité artistique est {name}',
  'components.share.messages.quiz_personality.email':
    'Bonjour :)\n\nJe viens de faire le quiz de personnalité Rise Art et apparemment, je suis {name}. Découvrez ce que cela signifie sur mon style et mes goûts artistiques. Tenez-moi au courant si vous réalisez le quizz, nous verrons si nous sommes compatibles !\n\n\nLink: {link}',
  'components.share.messages.quiz_personality.whatsapp':
    'Hey :) Je viens de faire le quiz de personnalité Rise Art et apparemment, je suis {name}. Découvrez ce que cela signifie sur mon style et mes goûts artistiques. Tenez-moi au courant si vous réalisez le quizz, nous verrons si nous sommes compatibles !',
  'components.share.messages.quiz_personality.messenger': '',
  'components.share.messages.quiz_personality.link': '',
  'components.share.messages.artistProfile.facebook': 'Découvrez {artist} sur Rise Art ',
  'components.share.messages.artistProfile.twitter': 'Découvrez {artist} sur Rise Art ',
  'components.share.messages.artistProfile.pinterest':
    '\nDécouvrez {artist} sur Rise Art\n\n{url}\n',
  'components.share.messages.artistProfile.emailSubject': 'Découvrez {artist}',
  'components.share.messages.artistProfile.email': '\nDécouvrez {artist} sur Rise Art\n\n{url}',
  'components.share.messages.artistProfile.whatsapp': 'Découvrez {artist} sur Rise Art ',
  'components.share.messages.artistProfile.messenger': 'Découvrez {artist} sur Rise Art ',
  'components.share.messages.artistProfile.link': 'Découvrez {artist} sur Rise Art ',
  'components.share.messages.event.facebook':
    "Découvrez l'événement {name} sur Rise Art @riseart #RiseArt ",
  'components.share.messages.event.twitter':
    "Découvrez l'événement {name} sur Rise Art @riseart #RiseArt ",
  'components.share.messages.event.pinterest':
    "\nDécouvrez l'événement {name} sur Rise Art @riseart #RiseArt\n\n{link}\n",
  'components.share.messages.event.emailSubject':
    "Découvrez l'événement {name} sur Rise Art @riseart #RiseArt ",
  'components.share.messages.event.email':
    "\nDécouvrez l'événement {name} sur Rise Art @riseart #RiseArt\n\n",
  'components.share.messages.event.whatsapp':
    "Découvrez l'événement {name} sur Rise Art {link} @riseart #RiseArt ",
  'components.share.messages.event.messenger':
    "Découvrez l'événement {name} sur Rise Art {link} @riseart #RiseArt ",
  'components.share.messages.event.link':
    "Découvrez l'événement {name} sur Rise Art {link} @riseart #RiseArt ",
  'components.quiz.start_test': 'Commencer le test',
  'components.quiz.landing_page_arrow':
    "Envie d'en savoir plus sur la science qui se cache derrière ?",
  'components.quiz.back_button': 'retour',
  'components.quiz.next_button': 'suivant',
  'components.quiz.ready_button': 'Je suis prêt(e)',
  'components.quiz.lets_go_button': "C'est parti'",
  'components.quiz.swipe_left': "Glissez VERS LA GAUCHE si vous N'AIMEZ PAS l'œuvre",
  'components.quiz.swipe_right': "Glissez VERS LA DROITE si vous AIMEZ l'œuvre",
  'components.quiz.swipe_btn_text': "J'ai compris",
  'components.quiz.share_results_title':
    'Partagez vos résultats avec vos amis et comparez vos résultats',
  'components.quiz.share_art_personality': 'Partagez votre personnalité artistique',
  'components.quiz.share_art_personality_hint': 'et comparez vos résultats à ceux de vos amis',
  'components.quiz.share_results': 'Partagez vos résultats',
  'components.quiz.result_banner_heading': 'Vous êtes',
  'components.quiz.result_score_averages': 'Moyenne des résultats:',
  'components.quiz.result_compared_title': 'Vous voici, en comparaison aux autres',
  'components.quiz.result_collection_title': 'Collections pour {name}',
  'components.quiz.result_artists_title': 'Artistes pour {name}',
  'components.quiz.result_recommended_title': 'Art pour {name}',
  'components.quiz.result_recommended_hint': 'basé sur vos scores',
  'components.quiz.result_recommend_title':
    'Invitez vos amis à faire le quizz pour connaitre leur personnalité artistique',
  'components.quiz.results_chart_score_label': 'Vous',
  'components.quiz.discover_your_personality': 'Découvrez votre personnalité',
  'components.quiz.discover_visual_preferences': 'Découvrez ce que vos choix disent de vous',
  'components.quiz.take_test': 'Faire le test',
  'components.quiz.art_personality': 'PERSONNALITÉ ARTISTIQUE',
  'components.quiz.other_art_personalities': 'Autres personnalités artistiques',
  'components.quiz.profile_compared_to_others':
    'Voici les scores de {name}, comparés à ceux des autres',
  'components.quiz.result_trait_arousing':
    'Utilisation diversifiée des couleurs ; rouge ; scènes intenses ; traits vifs et irréguliers ; cubisme.',
  'components.quiz.result_trait_minimalist':
    'Simple ; structuré ; motifs récurrents ; thèmes récurrents ; traits unifiés ; géométrique ; simple.',
  'components.quiz.result_trait_figurative':
    'Paysages ; portraits ; représentation ; non réflexif ; information contextuelle non requise ; récit cohérent ; traditionnel ; impressionnisme.',
  'components.quiz.result_trait_shocking':
    'Imagerie non linéaire, potentiellement violente, morbide, sexuelle ; hostile',
  'components.quiz.auth_page_text.main_text': 'Félicitations !',
  'components.quiz.auth_page_text.hint_text':
    'Veuillez renseigner vos informations pour découvrir votre personnalité artistique.',
  'components.quiz.auth_page_text.bottom_text':
    'Nous ne pourrons pas enregistrer ou vous envoyer vos résultats si vous quittez cette page sans renseigner vos informations.',
  'components.quiz.auth_page_text.continue_with': 'Poursuivre',
  'components.quiz.auth_page_text.send_email_results':
    'ou inscrivez-vous pour recevoir vos résultats par e-mail',
  'components.quiz.auth_page_text.subscribe_button': 'Voir mes résultats',
  'components.quiz.validation_messages.default_message':
    'Les informations que vous avez saisies ne sont pas valides. Veuillez les corriger',
  'components.quiz.errors.questions_not_loaded':
    "Nous n'avons pas pu charger la page. Veuillez vérifier votre connexion Internet et rafraîchir cette page",
  'components.quiz.errors.result_page_not_loaded':
    "Nous n'avons pas pu charger les résultats du quiz. Veuillez vérifier votre connexion Internet et rafraîchir cette page",
  'components.quiz.errors.submit_answers': "Nous n'avons pas pu envoyer vos réponses au serveur.",
  'components.collections.curator_text': 'Sélectionné par {name} {curatorText}',
  'components.collections.curator_text_short': 'Sélectionné par {name}',
  'components.collections.errors.banner_data_not_loaded':
    "Nous n'avons pas pu charger la page. Veuillez vérifier votre connexion Internet et rafraîchir cette page",
  'components.collections.errors.collection_list_not_loaded':
    "Nous n'avons pas pu charger la liste des collections. Veuillez vérifier votre connexion Internet et rafraîchir cette page.",
  'components.collections.list_title': 'Nos collections',
  'components.auth.effective_date': 'Publié le: {date}',
  'components.auth.facebook_signin': 'Se connecter avec Facebook',
  'components.auth.facebook_signup': "S'inscrire avec Facebook",
  'components.auth.google_signin': 'Se connecter avec Google',
  'components.auth.google_signup': "S'inscrire avec Google",
  'components.auth.signin': 'Se connecter à Rise Art',
  'components.auth.signup': 'Inscrivez-vous avec votre compte Rise Art',
  'components.auth.signup_social': "S'inscrire à Rise Art",
  'components.auth.signupTab': 'Nouveau sur Rise Art ?',
  'components.auth.loginTab': 'Déjà inscrit ?',
  'components.media.noVIdeoSupport': 'Votre navigateur ne prend pas en charge la balise vidéo',
  'components.pagination.page': 'Page',
  'components.pagination.next': 'Page suivante',
  'components.pagination.previous': 'Page précédente',
  'components.pagination.infiniteCounter':
    '{count, plural, =0 {aucune pièce trouvée} other {montrant {countFormatted} pièces}}',
  'components.pagination.sliderCounter':
    '{total, plural, =0 {aucune pièce trouvée} other {montrant {from}-{to} de {totalFormatted}}}',
  'components.cart.myBasket': 'Mon panier',
  'components.cart.yourBasket': 'Votre panier',
  'components.cart.yourRental': 'Votre locaton',
  'components.cart.printOrder': 'Imprimer la confirmation de commande',
  'components.cart.freeShipping': 'Gratuit',
  'components.cart.total': 'Total',
  'components.cart.subtotal': 'Sous-total',
  'components.cart.shipping': 'Livraison',
  'components.cart.qty': 'Quantité',
  'components.cart.secure': 'Sécurisé',
  'components.cart.sslEncryption': 'Cryptage SSL',
  'components.cart.discountCode': 'Code de réduction',
  'components.cart.credit': 'Crédit',
  'components.cart.giftCard': 'Carte Cadeau',
  'components.cart.continueToCheckout': 'Continuer vers le paiement',
  'components.cart.paymentOptions': 'Options de paiement',
  'components.cart.safeCheckout': 'Paiement sécurisé',
  'components.cart.rentalCreditNote':
    'Pour valider vos informations de paiement et configurer votre compte de location, nous devrons débiter un montant minimal sur votre carte.',
  'components.cart.instalmentsHint': 'Vous souhaitez étaler le coût sur 10 mois sans intérêt ?',
  'components.cart.customDutiesHint': "Des droits de douane peuvent s'appliquer,",
  'components.cart.checkHere': 'cochez ici',
  'components.cart.countItems':
    "{count, plural, =0 {pas d'articles} one {# article} other {# articles}}",
  'components.cart.rentalDayDuration':
    '{days, plural, one {# jour de location} other {# jours de location}}',
  'components.cart.rentalForthnightDuration':
    '{fortnights, plural, one {location de # quinzaine} other {location de # quinzaines}}',
  'components.cart.rentalMonthDuration':
    '{months, plural, one {# mois de location} other {# mois de location}}',
  'components.cart.rentalYearDuration':
    '{years, plural, one {# an de location} other {# ans de location}}',
  'components.cart.offer': 'Offre appliquée',
  'components.cart.viewBasket': 'Voir panier',
  'components.cart.checkout': 'Paiement',
  'components.cart.usingCouponCode': 'Utilisation du bon de réduction {couponCode}',
  'components.cart.customCharges': "Des droits de douane peuvent s`'appliquer, vérifiez {link}",
  'components.cart.customChargesTitle': "Frais d'expédition et de douane",
  'components.cart.sidebarBasketEmptyText': 'Votre panier est vide.',
  'components.cart.basketEmptyTitle': 'Votre panier est vide',
  'components.cart.basketEmptyText':
    'Votre panier est vide. Vous pouvez ajouter des articles au panier depuis votre Wishlist ci-dessous ou vous pouvez cliquer sur {link} pour continuer vos achats',
  'components.cart.rentalEmptyTitle': 'Aucune location sélectionnée',
  'components.cart.rentalEmptyText':
    "Aucune location n'est actuellement sélectionnée. Vous pouvez louer n'importe quelle œuvre à partir d'une page œuvre. Cliquez sur {link} pour continuer vos achats.",
  'components.cart.yourCredit': 'Votre crédit',
  'components.cart.gotGiftCart': 'Vous avez une Carte Cadeau ?',
  'components.cart.useDiscount': 'Utilisez votre code promo',
  'components.cart.noVouchers': 'Aucun code promo lié à votre compte',
  'components.cart.signInCredit1': 'Veuillez ',
  'components.cart.signInCredit2': 'vous inscrire',
  'components.cart.signInCredit3': 'pour utiliser votre crédit.',
  'components.cart.redeemCredit1': 'Veuillez le récupérer ',
  'components.cart.redeemCredit2': 'ici',
  'components.cart.redeemCredit3':
    'pour obtenir votre crédit sur votre compte, prêt à être utilisé lors du paiement.',
  'components.cart.signInRedeem1': 'Veuillez ',
  'components.cart.signInRedeem2': 'vous connecter',
  'components.cart.signInRedeem3':
    ' pour récupérer votre carte de crédit et obtenir vos crédits sur votre compte, prêts à être utilisés lors du paiement.',
  'components.cart.useCredit': 'Utiliser mon crédit pour cette commande',
  'components.cart.redeemGiftCard': 'Utiliser votre Carte Cadeau',
  'components.cart.redeemGiftDesc':
    'Obtenez des crédits dans votre compte prêts à être utilisés lors du paiement',
  'components.cart.giftCardCode': 'Code de la Carte Cadeau',
  'components.cart.giftCardSecurity': 'Code de sécurité',
  'components.cart.giftCardSecurityDesc':
    'Si votre Carte Cadeau inclut un code de sécurité, veuillez le fournir pour récupérer votre crédit.',
  'components.cart.shipsReadyToHangTooltipHeading': 'Location prête à être accrochée',
  'components.cart.shipsReadyToHangTooltipDescription':
    'Toutes les locations sont expédiées prêtes à être accrochées.',
  'components.cart.confirmationHeading': 'Merci pour votre commande',
  'components.cart.confirmationHeadingShort': 'Merci',
  'components.cart.confirmationDetailsHeading': 'Détails de la commande #{orderId}',
  'components.cart.confirmationDetailsHeadingShort': 'Commande #{orderId}',
  'components.cart.confirmationDetailsDescription':
    "Un e-mail de confirmation vous sera envoyé sous peu à l'adresse {email}",
  'components.cart.confirmationSubscribeHeading': 'Recevez nos conseils par e-mail',
  'components.cart.confirmationSubscribeButton': "Oui, je m'inscris",
  'components.cart.confirmationSubscribeDescription':
    'Nos curateurs vous partageront de nombreuses idées pour trouver votre œuvre idéale ! Chaque semaine, vous découvrirez nos dernières collections et toutes nos dernières actualités pour ne rien manquer.',
  'components.cart.confirmationBlogHeading': 'Fraîchement arrivé sur notre blog',
  'components.cart.confirmationReviewHint':
    'Veuillez vérifier votre commande et nous contacter si vous avez des questions ou des problèmes. Toutes les cartes ou adresses enregistrées seront conservées pour votre prochain achat et pourront être modifiées sur votre',
  'components.cart.confirmationReviewReturnPolicy':
    'Pour des informations sur les retours, veuillez consulter notre',
  'components.cart.confirmationReviewActionBtn': 'Continuer vos achats',
  'components.cart.creditApplied': "Le crédit a été appliqué au panier d'achat",
  'components.cart.creditRemoved': 'Le crédit a été supprimé du panier d’achat',
  'components.checkout.steps.info': 'Mes informations',
  'components.checkout.steps.infoShort': 'Contact',
  'components.checkout.steps.shipping': 'Adresse de livraison',
  'components.checkout.steps.shippingShort': 'Livraison',
  'components.checkout.steps.addShipping': 'Nouvelle adresse de livraison',
  'components.checkout.steps.editShipping': "Modifier l'adresse de livraison",
  'components.checkout.steps.billing': 'Paiement',
  'components.checkout.steps.billingAddress': 'Adresse de facturation',
  'components.checkout.cards.visa': 'Visa',
  'components.checkout.cards.master': 'Mastercard',
  'components.checkout.cards.maestro': 'Maestro',
  'components.checkout.cards.amex': 'American Express',
  'components.checkout.cards.diners': 'Diners Club',
  'components.checkout.cards.discover': 'Discover',
  'components.checkout.cards.jcb': 'JCB',
  'components.checkout.cards.unionpay': 'UnionPay',
  'components.checkout.cards.unknown': 'Carte inconnue',
  'components.checkout.cards.default': 'Carte',
  'components.checkout.orderDate': 'Date de commande',
  'components.checkout.yourInformation': 'Vos informations',
  'components.checkout.paymentMethod': 'Méthode de paiement',
  'components.checkout.continueToDelivery': 'Continuer vers la livraison',
  'components.checkout.deliverToAddress': 'Livrer à cette adresse',
  'components.checkout.continueToReview': 'Continuer vers la vérification de la commande',
  'components.checkout.deliveryHint':
    "Votre colis nécessitera une signature à la livraison, veuillez vous assurer de fournir une adresse où quelqu'un sera disponible pour le recevoir.",
  'components.checkout.paymentHint': 'Toutes les transactions sont sécurisées et chiffrées.',
  'components.checkout.reviewOrder': 'Vérifier votre commande',
  'components.checkout.reviewOrderHint':
    "Vous ne serez débité qu'une fois la vérification de la commande effectuée.",
  'components.checkout.placeOrder': 'Passer votre commande',
  'components.checkout.placeOrderHint':
    "En passant votre commande, vous acceptez nos {terms}, notre {policy} et notre {returnPolicy}. Vous consentez également au stockage de certaines de vos données par Rise Art, qui pourront être utilisées pour améliorer votre expérience d'achat à l'avenir.",
  'components.checkout.checkoutButtonExternal': 'Continuer vers {paymentType}',
  'components.checkout.creditHeading': 'Cartes Cadeaux et crédits',
  'components.checkout.creditLabel':
    'Utiliser mon crédit pour cette commande. Votre crédit total disponible est de {credit}',
  'components.checkout.creditDescription1':
    'Vous avez une Carte Cadeau ? Ajoutez-la à votre solde de chèques-cadeaux',
  'components.checkout.creditDescription2': " avant de pouvoir l'utiliser lors du paiement.",
  'components.checkout.cardValidThru': "Valable jusqu'à",
  'components.checkout.addPayment': 'Modifier le paiement',
  'components.checkout.usePayment': 'Sélectionner le paiement',
  'components.checkout.useAddress': 'Utiliser cette adresse',
  'components.checkout.paymentDetails': 'Modalités de paiement',
  'components.checkout.yourSavedCards': 'Vos cartes enregistrées',
  'components.checkout.usedCredits': 'Crédits Rise Art',
  'components.checkout.useCredits': 'Utiliser <strong>mes crédits</strong> pour cette commande :',
  'components.checkout.useCard':
    'Utiliser <strong>la carte</strong> suivante pour cette commande :',
  'components.checkout.useCardAndCredits':
    'Utiliser <strong>mes crédits</strong> pour cette commande et <strong>la carte</strong> suivante :',
  'components.checkout.usePaymentType': 'Utiliser <strong>{paymentType}</strong>',
  'components.checkout.usePaymentTypeAndCredits':
    'Utiliser <strong>mes crédits</strong> et <strong>{paymentType}</strong> pour cette commande',
  'components.checkout.securityCodeHintTitle': 'Code de sécurité',
  'components.checkout.securityCodeHintDescription':
    "Pour Visa et Mastercard, il s'agit des trois derniers chiffres situés au dos de votre carte, dans la bande de signature",
  'components.checkout.savePayment':
    "Enregistrer les détails de la carte et l'adresse de facturation pour la prochaine fois",
  'components.checkout.saveShippingAddress':
    "Enregistrer l'adresse de livraison pour la prochaine fois",
  'components.checkout.declinedPayment':
    'Le paiement a échoué. Si vous rencontrez toujours des problèmes avec les paiements, veuillez nous contacter.',
  'components.checkout.notLoadedStripe':
    'Veuillez vérifier votre connexion réseau et réessayer. Si vous rencontrez toujours des problèmes avec les paiements, veuillez nous contacter.',
  'components.checkout.footerMenu.payment': 'Paiement sécurisé',
  'components.checkout.footerMenu.return': 'Politique de retour',
  'components.checkout.footerMenu.returnTruncated': 'Retour',
  'components.checkout.footerMenu.contact': 'Contactez-nous',
  'components.checkout.footerMenu.contactTruncated': 'Contacts',
  'components.checkout.order.state.new': 'Nouveau',
  'components.checkout.order.state.pending_payment': 'Paiement en attente',
  'components.checkout.order.state.processing': 'Traitement',
  'components.checkout.order.state.complete': 'Complet',
  'components.checkout.order.state.closed': 'Fermé',
  'components.checkout.order.state.canceled': 'Annulé',
  'components.checkout.order.state.holded': 'Retenu',
  'components.checkout.order.state.payment_review': 'Revue des paiements',
  'components.checkout.paymentDescription': '{paymentType}',
  'components.checkout.paymentDescriptionAccount': 'Compte {paymentType} {account}',
  'components.checkout.paymentType.card': 'Carte de crédit ou de débit',
  'components.checkout.paymentType.paypal': 'PayPal',
  'components.checkout.paymentType.google_pay': 'Google Pay',
  'components.checkout.paymentType.apple_pay': 'Apple Pay',
  'components.checkout.paymentType.klarna': 'Klarna',
  'components.checkout.paymentType.sepa': 'Sepa Debit',
  'components.checkout.paymentType.sofort': 'Sofort',
  'components.checkout.paymentType.giropay': 'Giropay',
  'components.checkout.paymentType.wechat_pay': 'WeChat Pay',
  'components.checkout.paymentType.stripe': 'Paiements Stripe',
  'components.checkout.paymentCategory.pay_later': 'Payer plus tard',
  'components.checkout.paymentCategory.pay_with_financing': 'Payer avec un financement',
  'components.checkout.paymentCategory.pay_now': 'Payer maintenant',
  'components.checkout.paymentCategory.pay_in_installments': 'Paiement échelonné',
  'components.checkout.orderReviewMessage':
    "Votre commande est en attente de confirmation de paiement. Vous recevrez un courriel de confirmation de votre commande une fois que votre paiement aura été effectué. Si vous avez des questions, veuillez nous contacter à l'adresse <a>email</a> en mentionnant votre numéro de commande ci-dessous.",
  'components.checkout.orderCancelMessage':
    "Le paiement de votre commande a été annulé ou refusé. Si vous avez des questions, veuillez nous contacter à l'adresse <a>email</a> en mentionnant votre numéro de commande ci-dessous.",
  'components.checkout.paymentCancelMessage':
    'Le paiement de votre commande a été annulé ou refusé. Veuillez vérifier vos informations de paiement et réessayer.',
  'components.checkout.completePaymentInformation':
    'Veillez à compléter toutes les informations de paiement ci-dessus pour continuer.',
  'components.countdown.days': 'js',
  'components.countdown.hours': 'h',
  'components.countdown.minutes': 'min',
  'components.countdown.seconds': 'sec',
  'components.appMessages.artistSpotlight.title':
    "5 Artistes à la Une : <strong>BÉNÉFICIEZ DE 15% DE RÉDUCTION sur une <a>sélection d'œuvres</a></strong> avec le code <strong>SPOTLIGHT15</strong>.",
  'components.appMessages.artistSpotlight.titleMobile': '5 Artistes à la Une',
  'components.appMessages.artistSpotlight.subTitleMobile':
    "<strong>BÉNÉFICIEZ DE 15% DE RÉDUCTION sur une <a>sélection d'œuvres</a></strong> avec le code <strong>SPOTLIGHT15</strong>",
  'components.appMessages.artistSpotlight.titleCondensed': '5 Artistes à la Une',
  'components.appMessages.artistSpotlight.titleCondensedMobile': '5 Artistes à la Une',
  'components.appMessages.artistSpotlight.titleExtended':
    "5 Artistes à la Une : <strong>BÉNÉFICIEZ DE 15% DE RÉDUCTION sur une <a>sélection d'œuvres</a>.</strong>",
  'components.appMessages.artistSpotlight.titleExtendedMobile': '5 Artistes à la Une',
  'components.appMessages.artistSpotlight.subTitleExtendedMobile':
    "BÉNÉFICIEZ DE 15% DE RÉDUCTION sur une <strong><a>sélection d'œuvres</a>.</strong>",
  'components.appMessages.artistSpotlight.countdownLabelTruncated': ' ',
  'components.appMessages.artistSpotlight.codeExtended': 'Code <strong>SPOTLIGHT15</strong>',
  'components.appMessages.artistSpotlight.codeExtendedMobile':
    'Code <strong>SPOTLIGHT15</strong>. Fin le 28/04 à minuit',
  'components.appMessages.artistSpotlight.code': "Offre valable jusqu'au 28/04 à minuit.",
  'components.appMessages.promotions.visualSearch.title':
    'Cliquez sur {visualSimilarIcon} pour découvrir des œuvres similaires',
  'components.appMessages.promotions.visualSearch.text':
    'Utilisez la recherche visuelle pour trouver des œuvres de style similaire',
  'components.appMessages.promotions.visualSearch.action': 'Essayer maintenant',
  'components.appMessages.promotions.returns.title': 'Retours gratuits sous 14 jours',
  'components.appMessages.promotions.returns.action': 'Nous contacter',
  'components.appMessages.promotions.advisors.title': 'Parler avec nos curateurs',
  'components.appMessages.promotions.advisors.action': 'Nous contacter',
  'components.appMessages.promotions.reviews.title':
    'Recommandé par des collectionneurs et des marques de renom',
  'components.appMessages.promotions.reviews.text':
    'Découvrez notre dernière collaboration avec Soho Home.',
  'components.appMessages.promotions.reviews.action': 'Soho Home x Rise art',
  'components.appMessages.promotions.filters.title':
    "Affinez votre recherche. Trouvez l'œuvre d'art parfaite",
  'components.appMessages.promotions.filters.text':
    'Filtrez par taille, médium, prix et plus encore',
  'components.appMessages.promotions.filters.action': 'Essayer maintenant',
  'components.appMessages.promotions.saveWishlist.title': 'Enregistrez votre Wishlist',
  'components.appMessages.promotions.saveWishlist.text':
    "Vos œuvres d'art préférées sont temporairement enregistrées. Pour les conserver en toute sécurité, créez un compte Rise Art.",
  'components.appMessages.promotions.saveWishlist.action': 'Créer un compte',
  'components.blog.landingTitle': 'Edito',
  'components.blog.curatorsPicks': 'Sélections de nos Curateurs',
  'components.blog.mostRead': 'Les plus lus',
  'components.blog.moreFrom': 'Plus de',
  'components.blog.relatedArtworks': "Œuvres d'art liées",
  'components.blog.furtherReading': "Plus d'infos",
  'components.blog.subscribe.title': 'Abonnez-vous à notre newsletter',
  'components.blog.subscribe.subtitle':
    "Accédez en avant-première à nos Nouveautés, nos promotions et nos interviews d'artistes !",
  'components.blog.subscribe.submitBtn': "S'inscrire",
  'components.guides.landingTitle': "Nos guides sur l'art",
  'components.guides.landingDescription':
    "Découvrez les dernières actualités artistiques, les sorties récentes et les événements. Que vous souhaitiez rester à jour sur les nouvelles du monde de l'art, approfondir vos connaissances en histoire de l'art ou découvrir les principaux mouvements artistiques du moment, vous trouverez tout ce que vous devez savoir sur l'art contemporain avec le blog Rise Art.",
  'components.guides.furtherReading': 'Guides connexes',
  'components.guides.readNext': 'Lire la suite',
  'components.guides.subscribe.title': 'Restez informé',
  'components.guides.subscribe.subtitle':
    'Accédez en avant-première à nos contenus exclusifs et bien plus encore',
  'components.guides.subscribe.submitBtn': "S'inscrire",
  'components.wishlist.title': 'Ma Wishlist',
  'components.wishlist.noItems.title': 'Enregistrez vos œuvres préférées ici',
  'components.wishlist.noItems.descriptionLine1':
    "Cliquez sur {wishlistIcon} sous l'œuvre pour l'ajouter à votre wishlist.",
  'components.wishlist.noItems.descriptionLine2':
    'Essayez {visuallySimilarIcon} pour découvrir des œuvres similaires.',
  'components.wishlist.noItems.button': 'Explorez notre catalogue',
  'components.follow.noItems.title': 'Enregistrez vos artistes ici',
  'components.follow.noItems.descriptionLine1':
    'Utilisez {followIcon} pour ajouter des artistes à votre liste.',
  'components.follow.noItems.button': 'Parcourir les artistes',
  'components.me.artist.title': 'Mes Artistes',
  'components.me.privacy.title': 'Mes paramètres de confidentialité',
  'components.me.profile.title': 'Mon profil',
  'components.me.profile.updated': 'Votre profil a été mis à jour avec succès.',
  'components.me.account.title': 'Mon compte',
  'components.me.account.unlink': 'Dissocier de {network}',
  'components.me.account.deactivate.title': 'Désactiver mon compte',
  'components.me.account.deactivate.description1':
    "La désactivation de votre compte désactivera votre profil et supprimera vos informations personnelles de Rise Art. Certaines informations peuvent encore être visibles par d'autres personnes, telles que les événements que vous avez créés.",
  'components.me.account.deactivate.description2':
    'Vous pouvez <strong>réactiver</strong> votre compte simplement en revenant sur Rise Art et en vous connectant à votre compte. Nous espérons vous revoir bientôt !',
  'components.me.account.deactivate.button': 'Désactiver mon compte',
  'components.me.account.deactivate.confirmation':
    'Êtes-vous sûr de vouloir désactiver votre compte ?',
  'components.me.account.facebook.label': 'Facebook',
  'components.me.account.facebook.descriptionUnlinked':
    'Associez votre compte Facebook et utilisez-le pour vous connecter à Rise Art.',
  'components.me.account.facebook.descriptionLinked':
    'Vous pouvez utiliser votre compte Facebook pour vous connecter à Rise Art.',
  'components.me.account.google.label': 'Google',
  'components.me.account.google.descriptionUnlinked':
    'Associez votre compte Google et utilisez-le pour vous connecter à Rise Art.',
  'components.me.account.google.descriptionLinked':
    'Vous pouvez utiliser votre compte Google pour vous connecter à Rise Art.',
  'components.me.account.notLinked': 'Non associé',
  'components.me.account.linked': 'Associé',
  'components.me.account.emailLabel': 'E-mail',
  'components.me.account.setPassword': 'Définir un mot de passe',
  'components.me.account.changeEmail': "Modifier l'e-mail",
  'components.me.account.changePassword': 'Modifier le mot de passe',
  'components.me.account.unlinkTitle': 'Dissocier votre compte {network}',
  'components.me.account.unlinkConfirmationBody':
    'Êtes-vous sûr de vouloir dissocier votre compte ?',
  'components.me.account.createAccountDescription':
    'Avant de dissocier votre compte, vous devez créer un mot de passe. Veuillez définir un mot de passe que vous pouvez utiliser pour vous connecter avec votre adresse e-mail.',
  'components.me.offer.title': "Œuvres d'art {sku}",
  'components.me.offer.listTitle': 'Mes offres',
  'components.me.offer.offerTitle': 'Votre offre pour "{title}"',
  'components.me.offer.created': 'Créé le {date}',
  'components.me.offer.expires': 'Expire le {date}',
  'components.me.offer.viewDetails': 'Détails',
  'components.me.offer.percentDiscount': '{discount} réduction',
  'components.me.offer.status.pending': 'En attente',
  'components.me.offer.status.active': 'Acceptée',
  'components.me.offer.status.inactive': 'Inactif',
  'components.me.offer.state.declined': 'Refusé',
  'components.me.offer.state.expired': 'Expiré',
  'components.me.offer.state.unanswered': 'Sans réponse',
  'components.me.offer.state.used': 'Utilisé',
  'components.me.offer.state.canceled': 'Annulé',
  'components.me.offer.state.error': 'Inactif',
  'components.me.offer.accept.action': 'Accepter',
  'components.me.offer.accept.title': "Confirmer Accepter l'offre",
  'components.me.offer.accept.description': "Êtes-vous sûr de vouloir accepter l'offre ?",
  'components.me.offer.cancel.action': 'Annuler',
  'components.me.offer.cancel.title': "Confirmer l'annulation de l'offre",
  'components.me.offer.cancel.description': "Êtes-vous sûr de vouloir annuler l'offre ?",
  'components.me.offer.noItems.title': "Vous n'avez pas d'offre pour le moment",
  'components.me.offer.noItems.description':
    "Vous pouvez rechercher des œuvres d'art et placer des offres à partir de la page de détail de l'œuvre d'art.",
  'components.me.offer.noItems.button': 'Explorez notre catalogue',
  'components.search.artistTitleBasic': 'Artistes',
  'components.search.artistTitle': 'Artistes ({count})',
  'components.search.artworkTitleBasic': "Œuvres d'art",
  'components.search.artworkTitle': "Œuvres d'art ({count})",
  'components.search.searchByDomainButton': 'Afficher tout',
  'components.search.noSearchQuery':
    'Veuillez saisir une requête de recherche ci-dessus pour afficher vos résultats de recherche.',
  'components.search.noArtists':
    'Aucun artiste trouvé pour votre requête. Veuillez affiner votre requête.',
  'components.search.noArtworks':
    "Aucune œuvre d'art trouvée pour votre requête. Veuillez affiner votre requête.",
  'forms.common.close': 'Fermer',
  'forms.common.save': 'Enregistrer',
  'forms.common.saveChanges': 'Enregistrer les modifications',
  'forms.common.cancel': 'Annuler',
  'forms.common.reset': 'Réinitialiser',
  'forms.common.saveSettings': 'Enregistrer les paramètres',
  'forms.common.loading': 'Chargement...',
  'forms.common.wait': 'Patientez...',
  'forms.common.confirmLegal':
    'En cliquant sur ’{actionLabel}’ vous confirmez avoir lu, compris et accepté les {terms} et la {policy} de Rise Art.',
  'forms.common.terms_and_conditions': 'Conditions générales',
  'forms.common.privacy_policy': 'Politique de confidentialité',
  'forms.common.returnPolicies': 'Politique de retour',
  'forms.common.confirmRental':
    "J'ai lu et j'accepte les conditions de la {rental} et j'autorise Rise Art à envoyer des instructions à l'institution financière qui a émis ma carte pour effectuer des paiements depuis mon compte conformément aux termes de cet accord.",
  'forms.common.rentalAgreement': 'Contrat de location',
  'forms.common.confirmSubscribeTitle': 'Voulez-vous recevoir des mises à jour par e-mail ?',
  'forms.common.confirmSubscribeText':
    'Vous recevrez en avant-première nos offres du moment, nos dernières collections, nos conseils avisés et bien plus encore !',
  'forms.common.confirmSubscribeYes': 'Oui, je veux les recevoir.',
  'forms.common.confirmSubscribeNo':
    'Non, je ne souhaite pas être informé des dernières offres et actualités.',
  'forms.common.validation_messages.not_valid_email':
    "Ceci n'est pas une adresse e-mail valide, veuillez la corriger",
  'forms.common.validation_messages.not_empty_email':
    "L'e-mail est un champ obligatoire, merci de remplir le champ",
  'forms.common.validation_messages.not_valid_password':
    "Mot de passe invalide. Le mot de passe doit comporter au moins 6 caractères et ne doit pas contenir d'espaces",
  'forms.common.validation_messages.not_empty_password':
    'Le mot de passe est requis, veuillez en fournir un',
  'forms.common.validation_messages.not_valid_first_name':
    'Le prénom ne doit contenir que des lettres, des espaces ou des tirets (-) et ne peut pas contenir de chiffres',
  'forms.common.validation_messages.not_empty_first_name':
    'Le prénom est requis, veuillez en fournir un',
  'forms.common.validation_messages.not_valid_last_name':
    'Le nom de famille ne doit contenir que des lettres, des espaces ou des tirets (-) et ne peut pas contenir de chiffres',
  'forms.common.validation_messages.not_empty_last_name':
    'Le nom de famille est requis, veuillez en fournir un',
  'forms.common.validation_messages.notValidName':
    'Le nom ne doit contenir que des lettres, des espaces ou des tirets (-) et ne peut pas contenir de chiffres',
  'forms.common.validation_messages.not_empty_subscribe':
    "Veuillez vous assurer de sélectionner l'une des options",
  'forms.common.validation_messages.invalidPhone':
    "Ce numéro de téléphone n'est pas valide, veuillez le corriger",
  'forms.common.validation_messages.notValidOffer':
    "Le montant de l'offre est trop bas ou n'est pas un nombre valide",
  'forms.common.validation_messages.notEmptySearch':
    'La recherche est un champ obligatoire, merci de remplir le champ',
  'forms.common.validation_messages.notEmptyDiscount':
    'Le code de réduction est requis, merci de remplir le champ',
  'forms.common.validation_messages.notEmptyPostcode':
    'Le code postal est requis, merci de remplir le champ',
  'forms.common.validation_messages.notEmptyCity':
    'La ville est requise, merci de remplir le champ',
  'forms.common.validation_messages.notEmptyAddress':
    "L'adresse est requise, merci de remplir le champ",
  'forms.common.validation_messages.notEmptyCountry':
    'Le pays est requis, merci de remplir le champ',
  'forms.common.validation_messages.notValidBudget':
    "Ce n'est pas un budget valide, merci de le corriger",
  'forms.common.validation_messages.required': 'Ce champ est obligatoire',
  'forms.common.validation_messages.requiredByCountry':
    'Ce champ est obligatoire pour le pays sélectionné',
  'forms.common.validation_messages.maxPhoneLength':
    'Le numéro de téléphone comporte plus de 15 caractères',
  'forms.common.label.email': 'E-mail',
  'forms.common.label.firstName': 'Prénom',
  'forms.common.label.lastName': 'Nom de famille',
  'forms.common.label.birthDate': 'Date de naissance',
  'forms.common.label.gender': 'Genre',
  'forms.common.label.country': 'Pays',
  'forms.common.label.city': 'Ville',
  'forms.common.label.phone': 'Téléphone',
  'forms.checkout.firstName': 'Prénom',
  'forms.checkout.lastName': 'Nom de famille',
  'forms.checkout.email': 'E-mail',
  'forms.checkout.phone': 'Téléphone',
  'forms.checkout.company': 'Société',
  'forms.checkout.addressLine1': 'Adresse ligne 1',
  'forms.checkout.addressLine2': 'Adresse ligne 2',
  'forms.checkout.city': 'Ville',
  'forms.checkout.county': 'État, Département ou Région',
  'forms.checkout.postcode': 'Code postal',
  'forms.checkout.country': 'Pays',
  'forms.checkout.deliveryInstructions': 'Instructions de livraison',
  'forms.checkout.cardType': 'Type de carte',
  'forms.checkout.cardOwner': 'Nom sur la carte',
  'forms.checkout.cardNumber': 'Numéro de carte',
  'forms.checkout.expirityDate': "Date d'expiration",
  'forms.checkout.expirityDatePlaceholder': 'MM / AA',
  'forms.checkout.cvc': 'CVC',
  'forms.checkout.startDate': 'Date de début',
  'forms.checkout.issueNumber': "Numéro d'incident",
  'forms.checkout.savedCards': 'Mes cartes',
  'forms.checkout.validation.notEmptyCardNumber': 'Le numéro de carte est requis',
  'forms.checkout.validation.notSupportedCardNumber': 'Type de carte non pris en charge',
  'forms.checkout.validation.notCompleteCardNumber': 'Numéro de carte incomplet',
  'forms.checkout.validation.notValidCardNumber': 'Numéro de carte invalide',
  'forms.checkout.validation.notEmptyCardOwner': 'Le nom sur la carte est requis',
  'forms.checkout.validation.notEmptyCardValidThru': 'La date de validité de la carte est requise',
  'forms.checkout.validation.notValidCardValidThru':
    'La date de validité de la carte doit être une date postérieure',
  'forms.checkout.validation.cardStartDateNotInPast':
    'La date de début de validité de la carte doit être antérieure',
  'forms.checkout.validation.notEmptyCardSecurity': 'Le code de sécurité de la carte est requis',
  'forms.checkout.validation.notValidCardSecurity': 'CVC invalide',
  'forms.regionalForm.localeSelectLabel': 'Langue',
  'forms.regionalForm.currencySelectLabel': 'Afficher les prix en',
  'forms.regionalForm.countrySelectLabel': 'Livraison vers',
  'forms.regionalForm.unitsSelectLabel': "Unités d'affichage",
  'forms.regionalForm.units.metric': 'Métrique (cm, kg)',
  'forms.regionalForm.units.imperial': 'Impérial (in, lb)',
  'forms.subscribeForm.messages.error':
    'Nous avons rencontré un problème lors de votre inscription à notre newsletter, veuillez réessayer ultérieurement ou nous contacter',
  'forms.subscribeForm.messages.warning':
    "L'adresse e-mail que vous avez fournie est déjà abonnée à notre newsletter.",
  'forms.subscribeForm.messages.success':
    "Merci de vous être abonné à notre newsletter et bienvenue dans la communauté Rise Art ! Veuillez vérifier votre boîte de réception : nous vous avons envoyé un e-mail pour confirmer votre inscription. Nous avons également inclus des informations supplémentaires qui pourraient vous être utiles. Si vous ne recevez pas l'e-mail dans les prochaines minutes, veuillez vérifier vos courriers indésirables.",
  'forms.subscribeForm.messages.successTitle': 'Succès',
  'forms.subscribeForm.placeholder': 'Adresse e-mail',
  'forms.subscribeForm.submitButtonText': "S'inscrire",
  'forms.subscribeForm.subscribeTerms':
    'En nous fournissant votre adresse e-mail, vous acceptez de recevoir des mises à jour par e-mail, y compris des offres spéciales, des conseils, nos nouvelles collections et des actualités artistiques. Vous pourrez vous désabonner à tout moment.',
  'forms.searchForm.placeholder': 'Rechercher',
  'forms.searchForm.submit': 'Rechercher sur le site',
  'forms.searchForm.suggestionTitle': 'Suggestions de recherche',
  'forms.searchForm.clear': 'Effacer la recherche',
  'forms.auth_form.placeholder.firstName': 'Prénom',
  'forms.auth_form.placeholder.lastName': 'Nom de famille',
  'forms.auth_form.placeholder.email': 'Adresse e-mail',
  'forms.auth_form.placeholder.password': 'Mot de passe',
  'forms.auth_form.placeholder.newPassword': 'Nouveau mot de passe',
  'forms.auth_form.placeholder.show': 'Afficher le mot de passe',
  'forms.auth_form.register_button_text': "S'inscrire",
  'forms.auth_form.login_button_text': 'Se connecter',
  'forms.auth_form.register_text': 'Déjà membre ?',
  'forms.auth_form.forget_password_text': 'Mot de passe oublié ?',
  'forms.auth_form.register_error':
    'Nous avons rencontré un problème lors de votre inscription sur notre site Web, veuillez réessayer ultérieurement ou nous contacter.',
  'forms.auth_form.login_error':
    'Nous avons rencontré un problème lors de votre connexion sur notre site Web, veuillez réessayer ultérieurement ou nous contacter.',
  'forms.auth_form.show_password_text': 'AFFICHER',
  'forms.auth_form.hide_password_text': 'MASQUER',
  'forms.auth_form.sign_up_text': 'Ou créez un compte Rise Art avec votre adresse e-mail',
  'forms.auth_form.sign_in_text': 'Ou connectez-vous avec votre compte Rise Art',
  'forms.auth_form.forgotPassword.title': 'Mot de passe oublié',
  'forms.auth_form.forgotPassword.subtitle':
    "Entrez l'adresse e-mail avec laquelle vous vous êtes inscrit sur Rise Art et nous vous enverrons des instructions pour réinitialiser votre mot de passe par e-mail.",
  'forms.auth_form.forgotPassword.button': 'Obtenir le mot de passe',
  'forms.auth_form.forgotPassword.success':
    "Nous vous avons envoyé un e-mail à l'adresse e-mail fournie. Veuillez vérifier votre compte e-mail et suivre les instructions pour réinitialiser votre mot de passe",
  'forms.auth_form.forgotPassword.successButton': "Aller à la page d'accueil",
  'forms.auth_form.resetPassword.title': 'Changer de mot de passe',
  'forms.auth_form.resetPassword.subtitle':
    'Entrez votre adresse e-mail et un nouveau mot de passe ci-dessous pour changer votre mot de passe.',
  'forms.auth_form.resetPassword.button': 'Changer de mot de passe',
  'forms.single_field_form.submit_text': 'Rejoindre',
  'forms.single_field_form.label_text': 'Restez informé',
  'forms.single_field_form.label_form_field_placeholder': 'Adresse e-mail',
  'forms.cmsEnquire.successTitle': 'Merci',
  'forms.cmsEnquire.success': "Merci d'avoir soumis votre demande.",
  'forms.emailEnquire.overlayTitle': 'Parler avec un conseiller',
  'forms.emailEnquire.send': 'Envoyer',
  'forms.emailEnquire.download': 'Télécharger maintenant',
  'forms.emailEnquire.placeholder.firstName': 'Prénom',
  'forms.emailEnquire.placeholder.lastName': 'Nom de famille',
  'forms.emailEnquire.placeholder.name': 'Nom',
  'forms.emailEnquire.placeholder.email': 'E-mail',
  'forms.emailEnquire.placeholder.phone': 'Téléphone',
  'forms.emailEnquire.placeholder.subject': 'Sujet',
  'forms.emailEnquire.placeholder.message': 'Message',
  'forms.emailEnquire.placeholder.company': 'Société',
  'forms.emailEnquire.placeholder.offer': 'Votre Offre',
  'forms.emailEnquire.placeholder.budget': 'Budget',
  'forms.emailEnquire.placeholder.moreDetails': 'Plus de détails',
  'forms.emailEnquire.placeholder.country': 'Pays',
  'forms.emailEnquire.placeholder.space': 'Espace',
  'forms.emailEnquire.placeholder.productPrice': 'Prix de liste',
  'forms.emailEnquire.subject.arrangeViewing': 'Organiser une visite',
  'forms.emailEnquire.subject.additionalImages': "Photos supplémentaires de l'œuvre",
  'forms.emailEnquire.subject.pricing': 'Tarification et disponibilité',
  'forms.emailEnquire.subject.shipping': 'Expédition',
  'forms.emailEnquire.subject.sellArtwork': "Je veux vendre l'œuvre de cet artiste",
  'forms.emailEnquire.subject.other': 'Autre',
  'forms.emailEnquire.space.livingRoom': 'Salon',
  'forms.emailEnquire.space.bedroom': 'Chambre',
  'forms.emailEnquire.space.kidsRoom': "Chambre d'enfant'",
  'forms.emailEnquire.space.kitchen': 'Cuisine',
  'forms.emailEnquire.space.other': 'Autre',
  'forms.emailEnquire.description':
    "Remplissez le formulaire pour qu'un de nos conseillers vous contacte.",
  'forms.emailEnquire.advisorsContactDescription':
    "Vous pouvez également nous appeler au <strong>{advisorPhone}</strong> ou discuter avec nous directement en utilisant l'icône de chat en bas à droite de chaque page.",
  'forms.emailEnquire.success':
    "Merci d'avoir soumis votre demande. Un de nos conseillers vous contactera prochainement. ",
  'forms.emailEnquire.successOffer':
    "Nous vous remercions d'avoir soumis votre offre. Nous avons prévenu l'artiste et prendrons contact avec vous dans les plus brefs délais.",
  'forms.emailEnquire.advisorInfo':
    '<strong>{advisorName}</strong>, notre conseiller artistique, vous répondra dès que possible.',
  'forms.enquireOffer.title': 'Faire une offre',
  'forms.enquireOffer.description':
    'Remplissez le formulaire pour faire une offre pour <strong>{artworkName}</strong> par <strong>{artistName}</strong>.',
  'forms.enquireOffer.offer': 'Offre',
  'forms.enquireOffer.submitBtn': 'Faire une offre',
  'forms.enquireArtistCommission.title': "Commander une œuvre d'art",
  'forms.enquireArtistCommission.description':
    'Remplissez le formulaire pour faire une demande de commande pour <strong>{artistName}</strong>.',
  'forms.enquireArtistCommission.submitBtn': 'Envoyer la demande',
  'forms.enquireArtistCommission.extra.moreDetails':
    'Pour commencer, avez-vous des idées concernant la taille, le médium, les couleurs ou des œuvres de référence ? Dites-nous tout !',
  'forms.cart.discountCodePlaceholder': 'Saisissez votre code de réduction',
  'forms.cart.voucherCode': "Code de bon d'achat",
  'forms.cart.voucherCodeHint': 'Saisissez le code à 16 chiffres (y compris les tirets)',
  'forms.cart.securityCode': 'Code de sécurité',
  'forms.cart.securityCodeHint':
    "Si votre bon d'achat comprend un code de sécurité, veuillez le fournir pour utiliser votre crédit.",
  'forms.cart.redeemGiftCard': 'Utiliser une Carte Cadeau',
  'forms.profile.privacy.save': 'Enregistrer les modifications',
  'forms.profile.privacy.fields.newsletter': 'recevoir la newsletter de Rise Art',
  'forms.profile.privacy.fields.activity_update': "recevoir les mises à jour d'activité",
  'forms.profile.privacy.fields.activity_weekly':
    "recevoir le récapitulatif hebdomadaire de l'activité",
  'forms.profile.privacy.fields.product_update': 'recevoir les mises à jour des œuvres',
  'forms.profile.privacy.fields.general': 'recevoir les e-mails généraux',
  'forms.profile.privacy.hints.newsletter':
    'Recevez la newsletter hebdomadaire de Rise Art et restez informé des dernières actualités et événements',
  'forms.profile.privacy.hints.activity_update':
    'Recevez des notifications sur les nouvelles activités sur votre profil (nouveaux abonnés, utilisateurs ajoutant vos œuvres à leurs favoris, etc.)',
  'forms.profile.privacy.hints.activity_weekly':
    'Recevez un récapitulatif hebdomadaire par e-mail des dernières activités sur votre profil',
  'forms.profile.privacy.hints.product_update':
    "Recevez des mises à jour concernant les œuvres des artistes qui m'intéressent",
  'forms.profile.privacy.hints.general':
    'Recevez des notifications générales (recommandations, rappels, etc.)',
  'forms.profile.account.newEmail': 'Nouvelle adresse e-mail',
  'forms.search.domain.all': 'Tout',
  'forms.search.domain.art': 'Œuvres',
  'forms.search.domain.artist': 'Artistes',
  'forms.search.domain.collection': 'Collections',
  'forms.search.yourSearchResults': 'Vos résultats de recherche pour :',
  'forms.search.yourArtworkSearchResults': "Vos résultats de recherche d'œuvres pour :",
  'forms.search.yourArtistSearchResults': "Vos résultats de recherche d'artistes pour :",
  'forms.search.visual.refresh': 'Actualiser',
  'forms.search.visual.restart': 'Recommencer',
  'forms.search.visual.yourSearchResults': 'Vos résultats de recherche similaires pour :',
  'forms.search.visual.help':
    "Cliquez sur l'icône de similarité visuelle des œuvres que vous aimez pour affiner votre recherche",
  'forms.search.visual.searchHint':
    'Vous ne savez pas quoi chercher ? Essayez notre <a>Recherche Visuelle</a>',
  'forms.search.visual.searchHintShort':
    'Vous ne savez pas quoi chercher ? Essayez <a>Recherche Visuelle</a>',
  'forms.search.visual.searchHintButton': 'Essayez notre <icon></icon> Recherche Visuelle',
  'forms.search.visual.actions.backToResult': 'Retour au résultat précédent',
  'forms.search.visual.actions.seeArtwork': "Voir les détails de l'œuvre",
  'forms.search.visual.actions.addToBasket': 'Ajouter au panier',
  'layouts.logo.title': 'Rise Art',
  'layouts.header.search.placeholder': 'Artiste, catégorie, sujet...',
  'layouts.header.regionalOverlayTitle': 'Paramètres régionaux',
  'layouts.header.topmenu.sellerConsole': 'Espace vendeur',
  'layouts.header.topmenu.myPrivacy': 'Confidentialité',
  'layouts.header.topmenu.myOffers': 'Mes offres',
  'layouts.header.topmenu.login': 'Connexion',
  'layouts.header.topmenu.cart': 'Panier',
  'layouts.header.topmenu.signIn': 'Se connecter',
  'layouts.header.topmenu.signUp': "S'inscrire",
  'layouts.header.topmenu.signOut': 'Se déconnecter',
  'layouts.header.topmenu.artistSignUp': "S'inscrire en tant qu'artiste",
  'layouts.header.topmenu.myRiseart': 'Mon Rise Art',
  'layouts.header.topmenu.mySettings': 'Mes paramètres',
  'layouts.header.topmenu.inviteFriends': 'Inviter des amis',
  'layouts.header.topmenu.myCredit': 'Mon crédit',
  'layouts.header.topmenu.giftCard': 'Carte Cadeau',
  'layouts.header.topmenu.basket': 'Panier',
  'layouts.header.topmenu.wishlist': 'Wishlist',
  'layouts.header.topmenu.myWishlist': 'Ma Wishlist',
  'layouts.header.topmenu.myArtists': 'Mes Artistes',
  'layouts.header.topmenu.profile': 'Mon Profil',
  'layouts.header.topmenu.account': 'Mon Compte',
  'layouts.header.topmenu.avatarLabel': 'Profil',
  'layouts.header.topmenu.recentArt': 'Mes dernières œuvres',
  'layouts.footer.title': 'Restez informé',
  'layouts.footer.subscribe.title': 'Abonnez-vous à notre newsletter.',
  'layouts.footer.subscribe.sub_title':
    'Soyez le premier à recevoir nos dernières actualités, mises à jour et offres !',
  'layouts.footer.zeroFinance': 'FINANCEMENT À 0 %',
  'layouts.footer.social.title': 'SUIVEZ-NOUS',
  'layouts.footer.social.facebook': 'Aimez-nous sur Facebook',
  'layouts.footer.social.twitter': 'Suivez-nous sur Twitter',
  'layouts.footer.social.pinterest': 'Suivez-nous sur Pinterest',
  'layouts.footer.social.instagram': 'Suivez-nous sur Instagram',
  'layouts.footer.bottom.copyright': 'Copyright',
  'layouts.footer.bottom.riseart': 'Rise Art Ltd.',
  'layouts.footer.bottom.company': 'Société enregistrée au Royaume-Uni 070992621',
  'layouts.footer.bottom.address': '41 Great Portland Street, London W1W 7LA, Royaume-Uni',
  'messages.notifications.titles.error': 'Erreur',
  'messages.notifications.titles.warning': 'Avertissement',
  'messages.notifications.titles.info': 'Attention',
  'messages.notifications.titles.success': 'Succès',
  'messages.application.cookie.message.short':
    "Les cookies nous aident à fournir nos services. En utilisant Rise Art, vous acceptez l'utilisation des cookies.",
  'messages.application.cookie.message.long':
    'Rise Art utilise des cookies pour fournir et améliorer la qualité de ses services ainsi que pour analyser le trafic. Sous réserve de votre accord, les cookies seront également utilisés pour diffuser de la publicité et pour personnaliser le contenu et les publicités que vous verrez.',
  'messages.application.cookie.reject': 'Non merci',
  'messages.application.cookie.accept': 'Accepter',
  'messages.application.browser_support':
    "Vous utilisez un navigateur qui n'est pas compatible avec ce site web, et certaines fonctionnalités pourraient ne pas fonctionner.",
  'messages.application.artfetch':
    "Artfetch a été acquis par Rise Art, de l'art contemporain sélectionné par des experts.",
  'messages.application.kazoart':
    "KAZoART devient Rise Art, achetez de l'art contemporain trié sur le volet par des experts. <a>Plus d'infos</a>",
  'messages.application.kinaesthesia':
    'EN CE MOMENT : Découvrez notre exposition virtuelle<span class="ra-show-for-medium"> - <a href="/fr/exposition/kinesthesie" title="KINESTHÉSIE : L\'Art en mouvement" class="ra-link ra-link-default">KINESTHÉSIE : L\'Art en mouvement</a></span><div class="ra-show-for-small-only"><a href="/fr/exposition/kinesthesie" title="KINESTHÉSIE : L\'Art en mouvement" class="ra-link ra-link-default">KINESTHÉSIE : L\'Art en mouvement</a></div>',
  'messages.application.error404':
    'Nous sommes désolés, la page que vous avez demandée a été déplacée ou supprimée.',
  'messages.application.freeShipping.line1': 'LIVRAISON GRATUITE',
  'messages.application.freeShipping.line2':
    "Pour toute commande minimum de {currencySign}250+ | Jusqu'au 13/08, minuit (GMT).",
  'messages.application.freeShipping.line2Truncated':
    'pour les commandes minimum de {currencySign}250<div>se termine le 13/08</div>',
  'messages.application.christmasDelivery.line1':
    '<a href="/fr/noel" title="OFFREZ DE L\'ART" class="ra-link ra-link-strong">OFFREZ DE L\'ART</a>',
  'messages.application.christmasDelivery.line2':
    '<strong>Vous souhaitez une livraison garantie avant Noël ?</strong> <a href="https://aide.riseart.com/article/391-noel" target="_blank" title="En savoir plus" class="ra-link ra-link-default">En savoir plus</a>',
  'messages.application.christmasDelivery.line2Truncated':
    '<strong>Vous souhaitez une livraison garantie avant Noël ?</strong> <a href="https://aide.riseart.com/article/391-noel" target="_blank" title="En savoir plus" class="ra-link ra-link-default">En savoir plus</a>',
  'messages.application.christmasGift.line1': 'Offrez le choix avec notre ',
  'messages.application.christmasGift.line2':
    '<a href="/fr/cartes-cadeaux" title="Carte cadeau Rise Art" class="ra-link ra-link-default">CARTE CADEAU RISE ART</a>',
  'options.layout.sq': 'Carré',
  'options.layout.fl': 'Fluide',
  'options.sort.art.ontherise': 'Tendance',
  'options.sort.art.alltimetop': 'Les plus populaires',
  'options.sort.art.latest': 'Dernières sorties',
  'options.sort.art.price': 'Prix',
  'options.sort.art.artist': 'Toutes les Œuvres',
  'options.sort.art.default': 'Toutes les Œuvres',
  'options.sort.art.relevance': 'Pertinence',
  'options.sort.artist.featured': 'Artistes à suivre',
  'options.sort.artist.latest': 'Nouveaux Artistes',
  'options.sort.artist.popularity': 'Artistes populaires',
  'options.sort.artist.alpha': 'A - Z',
  'options.sort.artist.relevance': 'Pertinence',
  'errors.default.title': 'Erreur',
  'errors.default.details':
    "Il y a eu une erreur dans l'application. Veuillez recharger la page et nous contacter si vous voyez toujours cette erreur.",
  'errors.pageNotFound.title': 'Page non trouvée',
  'errors.pageNotFound.details': "L'URL demandée n'a pas été trouvée sur ce serveur.",
  'errors.pageNotFound.htmlDetails':
    "L'URL demandée {notFoundUrl} n'a pas été trouvée sur ce serveur.",
  'errors.forbiddenException.title': 'Interdit',
  'errors.forbiddenException.details':
    "Vous ne disposez pas des privilèges d'accès requis pour accéder à cette ressource.",
  'errors.networkError.title': 'Erreur réseau',
  'errors.networkError.details': 'La connexion est limitée ou inexistante.',
  'errors.timeoutError.title': 'Temps de chargement dépassé',
  'errors.timeoutError.details':
    'Le chargement de certaines ressources depuis nos serveurs prend trop de temps. Veuillez vérifier votre connexion et réessayer.',
  'errors.invalidToken.title': "Erreur d'autorisation invalide",
  'errors.invalidToken.details':
    'Votre session a expiré. Veuillez rafraîchir la page pour renouveler la session.',
  'errors.apiServerError.title': 'Erreur du serveur',
  'errors.apiServerError.details':
    'Notre équipe est mobilisée pour résoudre ce problème. Merci pour votre patience.',
  'errors.notFoundVisitor.title': "Pas d'identité trouvée'",
  'errors.notFoundVisitor.details':
    "Il y a eu des problèmes de chargement de données lors de l'initialisation de l'application. Certaines fonctionnalités peuvent ne pas fonctionner correctement. Veuillez rafraîchir votre application !",
  'meta.home.pageTitle':
    "Art contemporain à vendre. Acheter de l'art en ligne. Galerie d'art en ligne.",
  'meta.home.title': "Acheter de l'Art en ligne | Art contemporain à vendre | Rise Art",
  'meta.home.keywords':
    "art contemporain, art abstrait contemporain, marchands d'art, art à vendre, sites d'art, acheter de l'art, acheter de l'art en ligne, art original, art original à vendre, acheter de l'art original, art original, art mural en toile, beaux-arts, beaux-arts à vendre, acheter des beaux-arts en ligne, art encadré, art moderne, art moderne à vendre, acheter de l'art moderne en ligne, art en ligne, galerie d'art en ligne, conseillers artistiques, service de conseil artistique, marchand d'art, marchand d'art en ligne, art sélectionné, curation artistique, aide à la décoration intérieure, art pour le bureau, art de bureau",
  'meta.home.description':
    "Découvrez et collectionnez des oeuvres d'art d'artistes contemporains à travers le monde ! Explorez notre galerie d'art en ligne avec des œuvres à vendre soigneusement sélectionnées par notre équipe de curateurs. Achetez ou louez de l'art en ligne en toute confiance avec un service de conseil artistique gratuit.",
  'meta.art.pageTitle':
    "Art contemporain à vendre. Acheter de l'art en ligne. Galerie d'art en ligne.",
  'meta.art.title': "Explorez notre catalogue | Acheter de l'art contemporain en ligne | Rise Art",
  'meta.art.keywords':
    "art contemporain, art abstrait contemporain, marchands d'art, art à vendre, sites d'art, acheter de l'art, acheter de l'art en ligne, art original, art original à vendre, acheter de l'art original, art original, art mural en toile, beaux-arts, beaux-arts à vendre, acheter des beaux-arts en ligne, art encadré, art moderne, art moderne à vendre, acheter de l'art moderne en ligne, art en ligne, galerie d'art en ligne, conseillers artistiques, service de conseil artistique, marchand d'art, marchand d'art en ligne, art sélectionné, curation artistique, aide à la décoration intérieure, art pour le bureau, art de bureau",
  'meta.art.description':
    "Découvrez et collectionnez des oeuvres d'art d'artistes contemporains à travers le monde ! Explorez notre galerie d'art en ligne avec des œuvres à vendre soigneusement sélectionnées par notre équipe de curateurs. Achetez ou louez de l'art en ligne en toute confiance avec un service de conseil artistique gratuit.",
  'meta.artDetails.pageTitle':
    "Art contemporain à vendre. Acheter de l'art en ligne. Galerie d'art en ligne.",
  'meta.artDetails.title': "Acheter de l'art contemporain en ligne | Rise Art",
  'meta.artDetails.keywords':
    "acheter de l'art en ligne, art original, art, galerie d'art en ligne, artistes, art contemporain, conseil artistique, art à vendre",
  'meta.artDetails.description':
    "Découvrez et collectionnez des œuvres d'art originales réalisées par des artistes contemporains passionnants du monde entier. Chaque artiste et chaque œuvre ont été minutieusement sélectionnés par notre équipe de curateurs. Achetez de l'art en ligne en toute confiance avec un service de conseil artistique gratuit et un délai gratuit de rétraction de 14 jours.",
  'meta.artDetails.dynamicMetaTitle': "{name} par {artist} | Acheter de l'art en ligne | Rise Art",
  'meta.artDetails.dynamicPageTitle': "Acheter {name} par {artist}. Galerie d'art en ligne.",
  'meta.artDetails.dynamicMetaDescription':
    "Trouvez {name} par {artist} en ligne. Choisissez parmi des milliers d'œuvres d'art contemporain d'artistes passionnants soigneusement sélectionnés par les curateurs de Rise Art. Achetez de l'art en ligne en toute confiance avec un service de conseil artistique gratuit.",
  'meta.artist.pageTitle':
    "Artistes contemporains à vendre. Acheter de l'art en ligne. Galerie d'art en ligne.",
  'meta.artist.title': "Parcourir les artistes | Acheter de l'art contemporain en ligne | Rise Art",
  'meta.artist.keywords':
    "art contemporain, art abstrait contemporain, marchands d'art, art à vendre, sites d'art, acheter de l'art, acheter de l'art en ligne, art original, art original à vendre, acheter de l'art original, art original, art mural en toile, beaux-arts, beaux-arts à vendre, acheter des beaux-arts en ligne, art encadré, art moderne, art moderne à vendre, acheter de l'art moderne en ligne, art en ligne, galerie d'art en ligne, conseillers artistiques, service de conseil artistique, marchand d'art, marchand d'art en ligne, art sélectionné, curation artistique, aide à la décoration intérieure, art pour le bureau, art de bureau",
  'meta.artist.description':
    'Browse contemporary art from exciting artists from around the world. Explore our online art gallery with artwork for sale including paintings, prints, sculpture, photography and drawing in a range of styles from abstract expressionism to pop-art. Buy or rent art online with confidence.',
  'meta.artistProfile.dynamicMetaTitle':
    "{name}, Artiste | Acheter de l'art contemporain en ligne | Rise Art",
  'meta.artistProfile.dynamicMetaDescription':
    "Découvrez la sélection d’œuvres d’art de l’artiste {name} à vendre. Achetez de l'art contemporain en ligne auprès d'un groupe d'artistes talentueux soigneusement sélectionnés par Rise Art.",
  'meta.artistProfile.dynamicMetaKeywords':
    "{name}, artistes, artistes célèbres, artistes célèbres, acheter de l'art en ligne, art abordable, art original, art, cadeaux uniques, estampes en édition limitée, louer de l'art, artistes, art contemporain, musées",
  'meta.artistArt.dynamicMetaTitle': 'Œuvres de {name} | Art contemporain à vendre | Rise Art',
  'meta.artistArt.dynamicMetaDescription':
    "Parcourez notre sélection d'œuvres à vendre de l'artiste {name}. Achetez de l'art contemporain en ligne par {name} sur Rise Art",
  'meta.artistArt.dynamicMetaKeywords':
    "{name}, artistes, artistes célèbres, artistes célèbres, acheter de l'art en ligne, art abordable, art original, art, cadeaux uniques, estampes en édition limitée, louer de l'art, artistes, art contemporain, musées",
  'meta.artistCv.dynamicMetaTitle': 'CV de {name} | Rise Art',
  'meta.artistStudio.dynamicMetaTitle': "Dans l'Atelier de {name} | Rise Art",
  'meta.artistNews.dynamicMetaTitle': 'Actualités et expositions de {name} | Rise Art',
  'meta.activity.pageTitle': 'Activité récente | Rise Art',
  'meta.activity.title': 'Activité récente | Rise Art',
  'meta.activity.keywords':
    "art, estampes en édition limitée, affiches, artiste, artiste émergent, art émergent, découvrir l'art, acheter de l'art, art original, communauté d'artistes, blog d'art, sélection d'artistes",
  'meta.activity.description':
    "Rise Art est une plateforme d'art en ligne où tout le monde peut découvrir, partager et acheter des œuvres d'art uniques de talents émergents du monde entier. Rise Art permet aux utilisateurs de se connecter directement aux artistes et à leurs œuvres en ligne, et présente une galerie exclusive d'œuvres sélectionnées à vendre",
  'meta.guides.pageTitle':
    "Guides d'art | Mouvements artistiques, Stylisme artistique, Artistes à suivre, Conseils | Rise Art",
  'meta.guides.title':
    "Guides d'art | Mouvements artistiques, Stylisme artistique, Artistes à suivre, Conseils | Rise Art",
  'meta.guides.keywords':
    "guides d'art, comment faire, stylisme artistique, mouvements artistiques, artistes en vedette, art contemporain, acheter de l'art, conseils artistiques",
  'meta.guides.description':
    "Explorez nos guides d'art pour découvrir des mouvements artistiques, des styles artistiques, des artistes à suivre et des conseils pratiques sur l'achat d'art, la décoration intérieure et plus encore. Apprenez comment choisir et apprécier l'art contemporain avec Rise Art.",
  'meta.guidesCategory.pageTitle':
    "Guides d'art | Mouvements artistiques, Stylisme artistique, Artistes à suivre, Conseils | Rise Art",
  'meta.guidesCategory.title':
    "Guides d'art | Mouvements artistiques, Stylisme artistique, Artistes à suivre, Conseils | Rise Art",
  'meta.guidesCategory.keywords':
    "guides d'art, comment faire, stylisme artistique, mouvements artistiques, artistes en vedette, art contemporain, acheter de l'art, conseils artistiques",
  'meta.guidesCategory.description':
    "Explorez nos guides d'art pour découvrir des mouvements artistiques, des styles artistiques, des artistes à suivre et des conseils pratiques sur l'achat d'art, la décoration intérieure et plus encore. Apprenez comment choisir et apprécier l'art contemporain avec Rise Art.",
  'meta.guidesCategory.dynamicMetaTitle':
    '{title} | Mouvements artistiques, Stylisme artistique, Artistes à suivre, Conseils | Rise Art',
  'meta.guidesCategory.dynamicMetaKeywords':
    "{title}, guides d'art, comment faire, stylisme artistique, mouvements artistiques, artistes en vedette, art contemporain, acheter de l'art, conseils",
  'meta.guidesCategory.dynamicMetaDescription':
    "Trouvez tout ce que vous devez savoir sur {title} avec nos guides d'art.",
  'meta.guidesArticle.title':
    "Guides d'art | Mouvements artistiques, Stylisme artistique, Artistes à suivre, Conseils | Rise Art",
  'meta.guidesArticle.keywords':
    "guides d'art, comment faire, stylisme artistique, mouvements artistiques, artistes en vedette, art contemporain, acheter de l'art, conseils artistiques",
  'meta.guidesArticle.description':
    'Retrouvez tous nos conseils en matière de style et design artistique, nos artistes à suivre, apprenez-en plus sur les mouvements artistiques, et bien plus encore !',
  'meta.guidesArticle.dynamicMetaTitle': '{title} | Rise Art',
  'meta.guidesArticle.dynamicMetaKeywords':
    "{title}, {tags}, guides d'art, comment faire, stylisme artistique, mouvements artistiques, artistes en vedette, art contemporain, acheter de l'art",
  'meta.guidesArticle.dynamicMetaDescription':
    'Retrouvez tous nos conseils en matière de style et design artistique, nos artistes à suivre, apprenez-en plus sur les mouvements artistiques, et bien plus encore !',
  'meta.blog.pageTitle':
    'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.blog.title': 'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.blog.keywords':
    "blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne, art contemporain, histoire de l'art, mouvements artistiques",
  'meta.blog.description':
    "Découvrez les dernières actualités artistiques, les nouveautés récentes et les événements. Que vous souhaitiez rester à jour sur les actualités du monde de l'art, en savoir plus sur l'histoire de l'art ou découvrir les principaux mouvements artistiques, vous trouverez tout ce que vous devez savoir sur l'art contemporain sur le blog Rise Art.",
  'meta.blogCategory.pageTitle':
    'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.blogCategory.title':
    'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.blogCategory.keywords':
    "blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne, art contemporain, histoire de l'art, mouvements artistiques",
  'meta.blogCategory.description':
    "Découvrez les dernières actualités artistiques, les nouveautés récentes et les événements. Que vous souhaitiez rester à jour sur les actualités du monde de l'art, en savoir plus sur l'histoire de l'art ou découvrir les principaux mouvements artistiques, vous trouverez tout ce que vous devez savoir sur l'art contemporain sur le blog Rise Art.",
  'meta.blogCategory.dynamicMetaTitle':
    '{title} | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.blogCategory.dynamicMetaKeywords':
    "{title}, blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne, art contemporain, histoire de l'art, mouvements artistiques",
  'meta.blogCategory.dynamicMetaDescription':
    "Découvrez les dernières actualités artistiques, les nouveautés récentes et les événements. Que vous souhaitiez rester à jour sur les actualités du monde de l'art, en savoir plus sur l'histoire de l'art ou découvrir les principaux mouvements artistiques, vous trouverez tout ce que vous devez savoir sur l'art contemporain sur le blog Rise Art.",
  'meta.blogArticle.title':
    'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.blogArticle.keywords':
    "blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne, art contemporain, histoire de l'art, mouvements artistiques",
  'meta.blogArticle.description':
    "Découvrez les dernières actualités artistiques, les nouveautés récentes et les événements. Que vous souhaitiez rester à jour sur les actualités du monde de l'art, en savoir plus sur l'histoire de l'art ou découvrir les principaux mouvements artistiques, vous trouverez tout ce que vous devez savoir sur l'art contemporain sur le blog Rise Art.",
  'meta.blogArticle.dynamicMetaTitle': '{title} | Rise Art',
  'meta.blogArticle.dynamicMetaKeywords':
    "{title}, {tags}, blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne",
  'meta.blogArticle.dynamicMetaDescription': '{summary}',
  'meta.guide.pageTitle':
    'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.guide.title': 'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.guide.keywords':
    "blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne",
  'meta.guide.description':
    "Découvrez les actualités artistiques, les nouveautés et les événements les plus récents grâce à notre blog Rise Art. Notre blog artistique est un excellent moyen d'en apprendre davantage sur l'art. Parcourez nos articles autour des mouvements artistiques, nos visites d'ateliers, nos interviews d'artistes et nos conseils pour acquérir une œuvre d'art !",
  'meta.guideCategory.pageTitle':
    'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.guideCategory.title':
    'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.guideCategory.keywords':
    "blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne",
  'meta.guideCategory.description':
    "Découvrez les actualités artistiques, les nouveautés et les événements les plus récents grâce à notre blog Rise Art. Notre blog artistique est un excellent moyen d'en apprendre davantage sur l'art. Parcourez nos articles autour des mouvements artistiques, nos visites d'ateliers, nos interviews d'artistes et nos conseils pour acquérir une œuvre d'art !",
  'meta.guideCategory.dynamicMetaTitle':
    '{title} | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.guideCategory.dynamicMetaKeywords':
    "{title}, blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne, mouvement artistique",
  'meta.guideCategory.dynamicMetaDescription':
    "Découvrez les actualités artistiques, les nouveautés et les événements les plus récents grâce à notre blog Rise Art. Notre blog artistique est un excellent moyen d'en apprendre davantage sur l'art. Parcourez nos articles autour des mouvements artistiques, nos visites d'ateliers, nos interviews d'artistes et nos conseils pour acquérir une œuvre d'art !",
  'meta.guideArticle.title':
    'Blog artistique | Actualités, Nouveautés, Événements artistiques | Rise Art',
  'meta.guideArticle.keywords':
    "blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne, mouvement artistique",
  'meta.guideArticle.description':
    "Découvrez les actualités artistiques, les nouveautés et les événements les plus récents grâce à notre blog Rise Art. Notre blog artistique est un excellent moyen d'en apprendre davantage sur l'art. Parcourez nos articles autour des mouvements artistiques, nos visites d'ateliers, nos interviews d'artistes et nos conseils pour acquérir une œuvre d'art !",
  'meta.guideArticle.dynamicMetaTitle': '{title} | Rise Art',
  'meta.guideArticle.dynamicMetaDescription':
    "Découvrez les actualités artistiques, les nouveautés et les événements les plus récents grâce à notre blog Rise Art. Notre blog artistique est un excellent moyen d'en apprendre davantage sur l'art. Parcourez nos articles autour des mouvements artistiques, nos visites d'ateliers, nos interviews d'artistes et nos conseils pour acquérir une œuvre d'art !",
  'meta.guideArticle.dynamicMetaKeywords':
    "{title}, {tags}, blog artistique, actualités artistiques, événements artistiques, galerie d'art en ligne, art contemporain, histoire de l'art, mouvements artistiques",
  'meta.event.pageTitle':
    'Découvrez et réservez les prochaines expositions et événements artistiques',
  'meta.event.title':
    'Événements artistiques | Prochains événements artistiques et en galerie | Rise Art',
  'meta.event.keywords':
    "Location d'art, Achat d'art, Art, Estampes en édition limitée, Affiches, Artiste, Artiste émergent, Art émergent, Découvrir l'art, Acheter de l'art, Art original, Social, Communauté d'artistes, Blog artistique, Art contemporain, Art diplômé, Art sélectionné, Art exclusif, Art abordable, Art original",
  'meta.event.description':
    "Tous les événements artistiques. Découvrez et réservez les prochaines expositions et événements en galerie. Utilisez Rise Art pour découvrir des expositions d'art passionnantes, des vernissages et des événements artistiques",
  'meta.event.dynamicMetaTitle': '{name} | Evénement artistique | Rise Art',
  'meta.event.dynamicMetaDescription':
    "RSVP et recevez des mises à jour pour {name} à {address} le {date}. Utilisez Rise Art pour découvrir les événements artistiques à venir et les nouvelles œuvres d'art d'artistes talentueux. Louez et achetez de l'art à partir d'une sélection experte de peintures, de photographies et d'estampes en édition limitée à des prix abordables.",
  'meta.event.dynamicMetaKeywords':
    "Location d'art, Achat d'art, Art, Estampes en édition limitée, Affiches, Artiste, Artiste émergent, Art émergent, Découverte de l'art, Achat d'art, Achat d'art, Art original, Social, Communauté d'artistes, Blog d'art, Art contemporain, Art diplômé, Art sélectionné, Art exclusif, Art abordable, Art original",
  'meta.eventList.pageTitle':
    "Découvrez et participez aux expositions et événements artistiques à venir dans les galeries d'art",
  'meta.eventList.title': 'Événements artistiques | Événements artistiques à venir | Rise Art',
  'meta.eventList.keywords':
    "Location d'art, Achat d'art, Art, Estampes en édition limitée, Affiches, Artiste, Artiste émergent, Art émergent, Découverte de l'art, Achat d'art, Achat d'art, Art original, Social, Communauté d'artistes, Blog d'art, Art contemporain, Art diplômé, Art sélectionné, Art exclusif, Art abordable, Art original",
  'meta.eventList.description':
    "Tous les événements artistiques. Découvrez et réservez les prochaines expositions et événements en galerie. Utilisez Rise Art pour découvrir des expositions d'art passionnantes, des vernissages et des événements artistiques",
  'meta.collectionList.pageTitle':
    "Découvrez des collections d'art. Achetez de l'art contemporain en ligne. Galerie d'art en ligne.",
  'meta.collectionList.title':
    "Découvrez des collections d'art. Achetez de l'art contemporain en ligne | Rise Art",
  'meta.collectionList.keywords':
    "collections d'art, collections thématiques d'art, achetez de l'art en ligne, art original, art, galerie d'art en ligne, artistes, art contemporain, conseil artistique, art à vendre",
  'meta.collectionList.description':
    "Découvrez des collections d'art sélectionnées par les curateurs de Rise Art.  Achetez de l'art en ligne en toute confiance avec un conseil artistique gratuit.",
  'meta.collectionDetails.pageTitle':
    "Découvrez des collections d'art. Achetez de l'art contemporain en ligne",
  'meta.collectionDetails.title': "Achetez de l'art contemporain en ligne | Rise Art",
  'meta.collectionDetails.keywords':
    "collections d'art, collections thématiques d'art, achetez de l'art en ligne, art original, art, galerie d'art en ligne, artistes, art contemporain, conseil artistique, art à vendre",
  'meta.collectionDetails.description':
    "Découvrez des collections d'art sélectionnées par les curateurs de Rise Art.  Achetez de l'art en ligne en toute confiance avec un conseil artistique gratuit.",
  'meta.collectionDetails.dynamicMetaTitle':
    "{name} | Achetez de l'art contemporain en ligne | Rise Art",
  'meta.collectionDetails.dynamicPageTitle':
    "La collection de {name} sur Rise Art. Galerie d'art en ligne.",
  'meta.collectionDetails.dynamicMetaDescription':
    "Découvrez la collection de {name} surn Rise Art. Explorez notre galerie d'art en ligne avec une large sélection de collections d'art soigneusement sélectionnées par nos spécialistes de l'art. Achetez de l'art en ligne en toute confiance avec des conseils artistiques gratuits.",
  'meta.myWishlist.pageTitle':
    "Achetez de l'art en ligne à des prix abordables. Galerie d'art en ligne.",
  'meta.myWishlist.title': "Votre Wishlist | Achetez de l'art en ligne | Rise Art",
  'meta.myWishlist.keywords':
    "collections d'art, collections thématiques d'art, achetez de l'art en ligne, art original, art, galerie d'art en ligne, artistes, art contemporain, conseil artistique, art à vendre",
  'meta.myWishlist.description':
    "Consultez et modifiez votre Wishlist. Découvrez de l'art contemporain sélectionné par des experts. Découvrez nos Œuvres originales parmi lesquelles des peintures, des sculptures, de la photographie et des éditions limitées.",
  'meta.myArtists.pageTitle':
    "Achetez de l'art en ligne à des prix abordables. Galerie d'art en ligne.",
  'meta.myArtists.title': 'Mes Artistes | Rise Art',
  'meta.myArtists.keywords':
    "collections d'art, collections thématiques d'art, achetez de l'art en ligne, art original, art, galerie d'art en ligne, artistes, art contemporain, conseil artistique, art à vendre",
  'meta.myArtists.description':
    "Visualisez vos offres d'art. Découvrez l'art contemporain trié sur le volet par des experts. Des œuvres d'art originales et abordables à vendre, notamment des peintures, des photographies et des tirages en édition limitée",
  'meta.myOffers.pageTitle':
    "Acheter de l'art en ligne à des prix abordables. Galerie d'art en ligne.",
  'meta.myOffers.title': 'Mes offres | Rise Art',
  'meta.myOffers.keywords':
    "collections d'art, collections thématiques d'art, achetez de l'art en ligne, art original, art, galerie d'art en ligne, artistes, art contemporain, conseil artistique, art à vendre",
  'meta.myOffers.description':
    "Visualisez vos offres d'art. Découvrez l'art contemporain trié sur le volet par des experts. Des œuvres d'art originales et abordables à vendre, notamment des peintures, des photographies et des tirages en édition limitée",
  'meta.myOffer.pageTitle':
    "Acheter de l'art en ligne à des prix abordables. Galerie d'art en ligne.",
  'meta.myOffer.title': 'Mon offre | Rise Art',
  'meta.myOffer.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.myOffer.description':
    'View your art offer details. Discover contemporary art hand-picked by experts. Affordable original art for sale including Paintings, Photography and Limited Edition Prints.',
  'meta.quiz.pageTitle':
    'Découvrez votre personnalité artistique sur Rise Art. Faites notre test de personnalité gratuit.',
  'meta.quiz.title':
    'Test de personnalité artistique | Découvrez votre style artistique | Rise Art',
  'meta.quiz.keywords':
    'test de personnalité, test de personnalité gratuit, art, quiz artistique, quiz de personnalité artistique',
  'meta.quiz.description':
    "Que dit l'art que vous aimez de votre personnalité ? Faites notre test de personnalité artistique gratuit et découvrez ce que vos préférences visuelles disent de vous.",
  'meta.quiz.ogTitle': 'Le Test de Personnalité Artistique de Rise Art',
  'meta.quiz.ogDescription':
    'Faites le test et découvrez quelle est votre personnalité artistique. Nous nous sommes basés sur des recherches en psychologie comportementale.',
  'meta.quizQuestions.pageTitle':
    'Découvrez votre personnalité artistique sur Rise Art. Faites notre test de personnalité gratuit.',
  'meta.quizQuestions.title':
    'Test de personnalité artistique | Découvrez votre style artistique | Rise Art',
  'meta.quizQuestions.keywords':
    'test de personnalité, test de personnalité gratuit, art, quiz artistique, quiz de personnalité artistique',
  'meta.quizQuestions.description':
    "Que dit l'art que vous aimez de votre personnalité ? Faites notre test de personnalité artistique gratuit et découvrez ce que vos préférences visuelles disent de vous.",
  'meta.quizResult.pageTitle': 'Votre personnalité artistique et votre goût artistique',
  'meta.quizResult.title': 'Votre personnalité artistique et votre goût artistique | Rise Art',
  'meta.quizResult.keywords':
    "art abordable, louer de l'art, acheter de l'art, quiz sur l'art, style d'art, quiz sur le style d'art",
  'meta.quizResult.description':
    "Recevez des recommandations personnalisées de nos conseillers artistiques en fonction de vos préférences artistiques. Achetez de l'art original en ligne auprès des meilleurs artistes. Découvrez des œuvres d'art originales qui vous correspondent !",
  'meta.quizProfile.pageTitle':
    'Découvrez votre personnalité artistique sur Rise Art. Faites notre test de personnalité gratuit.',
  'meta.quizProfile.title': 'Test de personnalité artistique | Rise Art',
  'meta.quizProfile.keywords':
    'test de personnalité, test de personnalité gratuit, art, quiz artistique, quiz de personnalité artistique',
  'meta.quizProfile.description':
    "Que dit l'art que vous aimez de votre personnalité ? Faites notre test de personnalité artistique gratuit et découvrez ce que vos préférences visuelles disent de vous.",
  'meta.quizProfile.dynamicMetaTitle': '{name} | Test de personnalité artistique | Rise Art',
  'meta.quizProfile.dynamicPageTitle':
    '{name}. Test de personnalité artistiqu. Faites notre test de personnalité gratuit..',
  'meta.quizProfile.dynamicMetaDescription':
    "Je suis {name}. Que dit l'art que vous aimez de votre personnalité ? Faites notre test de personnalité artistique gratuit et découvrez ce que vos préférences visuelles disent de vous.",
  'meta.quizRegister.pageTitle':
    'Découvrez votre personnalité artistique sur Rise Art. Faites notre test de personnalité artistique gratuit.',
  'meta.quizRegister.title':
    'Découvrez votre personnalité artistique | Test de personnalité artistique | Rise Art',
  'meta.quizRegister.keywords':
    'test de personnalité, test de personnalité gratuit, art, quiz artistique, quiz de personnalité artistique',
  'meta.quizRegister.description':
    "Recevez des recommandations personnalisées de nos conseillers artistiques en fonction de vos préférences artistiques. Achetez de l'art original en ligne auprès des meilleurs artistes. Découvrez des œuvres d'art originales qui vous correspondent !",
  'meta.quizLogin.pageTitle':
    'Découvrez votre personnalité artistique sur Rise Art. Faites notre test de personnalité artistique gratuit.',
  'meta.quizLogin.title':
    'Découvrez votre personnalité artistique | Test de personnalité artistique | Rise Art',
  'meta.quizLogin.keywords':
    'test de personnalité, test de personnalité gratuit, art, quiz artistique, quiz de personnalité artistique',
  'meta.quizLogin.description':
    "Recevez des recommandations personnalisées de nos conseillers artistiques en fonction de vos préférences artistiques. Achetez de l'art original en ligne auprès des meilleurs artistes. Découvrez des œuvres d'art originales qui vous correspondent !",
  'meta.cart.title': 'Panier | Rise Art',
  'meta.checkout.title': 'Paiement | Rise Art',
  'meta.privacy.pageTitle': 'Mes paramètres de confidentialité',
  'meta.privacy.title': 'Mes paramètres de confidentialité | Rise Art',
  'meta.profile.pageTitle': 'Mon Profil',
  'meta.profile.title': 'Mon Profil | Rise Art',
  'meta.account.pageTitle': 'Mon Compte',
  'meta.account.title': 'Mon Compte | Rise Art',
  'meta.login.pageTitle': 'Se connecter à Rise Art',
  'meta.login.title': 'Se connecter | Rise Art',
  'meta.register.pageTitle': "S'inscrire sur Rise Art",
  'meta.register.title': "S'inscrire | Rise Art",
  'meta.forgotPassword.pageTitle': 'Mot de passé oublié',
  'meta.forgotPassword.title': 'Mot de passé oublié | Rise Art',
  'meta.resetPassword.pageTitle': 'Modifier le mot de passe',
  'meta.resetPassword.title': 'Modifier le mot de passe | Rise Art',
  'meta.signout.pageTitle': 'Se déconnecter de Rise Art',
  'meta.signout.title': 'Se déconnecter | Rise Art',
  'meta.search.pageTitle': 'Rechercher',
  'meta.search.title': 'Rechercher | Rise Art',
  'meta.error.pageTitle': "Achetez de l'art en ligne à des prix abordables. Galerie d'art en ligne",
  'meta.error.title': "Achetez de l'art en ligne | Art abordable à vendre | Rise Art",
  'meta.error.keywords':
    "collections d'art, art abordable, collections thématiques d'art, achetez de l'art en ligne, art original, art, galerie d'art en ligne, artistes, art contemporain, conseil artistique, art à vendre",
  'meta.error.description':
    "Découvrez des œuvres d'art originales à prix abordable, notamment des peintures, de la photographie et des estampes en édition limitée.",
  'meta.common.pageTitle':
    "Achetez de l'art en ligne à des prix abordables. Galerie d'art en ligne.",
  'meta.common.title': "Achetez de l'art en ligne | Art abordable à vendre | Rise Art",
  'meta.common.keywords':
    "art, estampes en édition limitée, affiches, artiste, artiste émergent, art émergent, découvrez l'art, achetez de l'art, art original, social, communauté d'artistes, blog d'art, artistes sélectionnés",
  'meta.common.description':
    "Rise Art est une plateforme d'art en ligne où tout le monde peut découvrir, partager et acheter des œuvres d'art uniques de talents émergents du monde entier. Rise Art permet aux utilisateurs de se connecter directement aux artistes et à leurs œuvres en ligne, et présente une galerie exclusive d'œuvres sélectionnées à vendre",
};

export default function Locale({ children }) {
  return children(messages);
}
